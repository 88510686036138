// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-radio-button {
  font-weight: 700;
  font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/authentication/registration-step1/registration-step1.component.scss"],"names":[],"mappings":"AAEA;EACI,gBAAA;EACA,eAAA;AADJ","sourcesContent":["@import '../../../scss/variables';\n\n.mat-mdc-radio-button{\n    font-weight: 700;\n    font-size: 20px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
