import { ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService, StripeService } from '@services';
import { Dict, get, isNil, to, __DEV__, getError } from '@utils';

@Component({
	selector: 'payment-method-edit',
	template: `
		<!-- alert ‣ overall changes or errors -->
		<alert #alertRef [isOpen]="error | isNotNil" severity="error" variant="filled">
			{{ error }}
		</alert>

		<!-- title -->
		<div sx variant="dialog.header">
			<p sx variant="title">Edit card</p>
		</div>

		<!-- forms -->
		<div sx variant="dialog.content">
			<div sx [opacity]="isUpdating ? '0.5' : '1'">
				<payment-method-card
					actionsAreDisabled="true"
					[card]="data.card"
				></payment-method-card>
			</div>
			<br />
			<address
				#addressRef
				(onChange)="alertRef.close()"
				(onValid)="handleValid()"
				[config]="config"
				[edit]="true"
				format="stripe"
				initialFocus="name"
			></address>
		</div>

		<!-- actions -->
		<div sx variant="dialog.actions">
			<button (click)="close()" class="btn btn-default">Cancel</button>
			<button
				(click)="handleSubmit(data?.card?.id, addressRef.value)"
				[disabled]="isUpdating || !isValid || !addressRef.changed"
				class="btn btn-primary"
				sx
				variant="button.contained"
			>
				<span *ngIf="!isUpdating">Submit</span>
				<mat-spinner *ngIf="isUpdating" diameter="24"></mat-spinner>
			</button>
		</div>
	`,
})
export class PaymentMethodEdit {
	error: string;

	isUpdating: boolean = false;

	isValid: boolean = false;

	get config() {
		return {
			...this.data.card,
			name: {
				value: get(this, 'data.card.name'),
				placeholder: 'Cardholder',
			},
		};
	}

	/* ---------------------------------- */

	@ViewChild('addressRef', { static: true }) addressRef: any;

	@ViewChild('alertRef', { static: true }) alertRef: any;

	/* ---------------------------------- */

	constructor(
		protected auth: AuthService,
		protected cdr: ChangeDetectorRef,
		protected stripe: StripeService,
		public dialog: MatDialogRef<PaymentMethodEdit>,
		@Inject(MAT_DIALOG_DATA) public data: { card: any },
	) {
		// If this is a dev environment, enhance the dev ergonomics by making global var available.
		if (__DEV__) {
			if (isNil(window['editPaymentMethod'])) window['editPaymentMethod'] = this;
		}
	}

	_clearErrors = () => {
		this.error = null;
		return this.cdr.detectChanges();
	};

	/* ---------------------------------- */

	close = (card?: Dict): void => {
		this.dialog.close(card);
	};

	handleValid = () => {
		if (this.addressRef.valid) {
			this.isValid = true;
		}
		return this.cdr.detectChanges();
	};

	handleSubmit = async (cardID: string, address: Dict) => {
		this.isUpdating = true;
		this.addressRef.disable();

		const [err, card] = await to(this.stripe.updateCard(cardID, address));

		this.isUpdating = false;
		this.addressRef.enable();
		if (err) {
			this.error = getError(err);
			this.alertRef.open();
		} else {
			this.close(card);
		}
		return this.cdr.detectChanges();
	};
}
