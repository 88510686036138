import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { DialogComponent, DialogService } from "../../module/dialog/bootstrap-modal.module";
import { StaticTextService } from '../../services';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent{
  
  title: any;
  message: any;

  constructor(dialogService: DialogService, public text: StaticTextService,
    public dialogRef: MatDialogRef<ErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.title=this.data.title;
      this.message=this.data.message;
  }


  Close() {
    // this.result = true;
    // this.close();
    this.dialogRef.close({result:true})

  }

}
