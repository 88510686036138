// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rounded-container {
  display: flex;
  justify-content: space-between;
  background-color: #f3fbff;
  border-radius: 14px;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  border: 1px solid transparent;
}

.rounded-container:not(.selected):hover {
  border: 1px solid #76d0f4;
  background-color: #e5f5fd;
}

.selected {
  background-color: #76D0F4;
  color: #FFFFFF;
}

.corner {
  width: 50%; /* Each corner takes 50% width */
  border-radius: 14px;
}

.top-left {
  border-top-left-radius: 14px;
}

.top-right {
  border-top-right-radius: 14px;
  text-align: end;
}

.margin-bottom-15 {
  margin-bottom: 15px !important;
}

.accText {
  font-size: 19px;
  font-weight: bold;
  color: #ee4751;
}

.textBold {
  font-weight: bold;
}

.containerData {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.componentHeight {
  height: calc(100vh - 164px);
}

.logiComponentHeight {
  height: 750px;
}`, "",{"version":3,"sources":["webpack://./src/app/authentication/multi-account/multi-account.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,6BAAA;AACF;;AAEA;EACE,yBAAA;EACA,yBAAA;AACF;;AAEA;EACE,yBAAA;EACA,cAAA;AACF;;AAEA;EACE,UAAA,EAAA,gCAAA;EACA,mBAAA;AACF;;AAEA;EACE,4BAAA;AACF;;AAEA;EACE,6BAAA;EACA,eAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAEA;EACE,eAAA;EACA,iBAAA;EACA,cAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;AACF;;AAEA;EACE,2BAAA;AACF;;AAEA;EACE,aAAA;AACF","sourcesContent":[".rounded-container {\n  display: flex;\n  justify-content: space-between;\n  background-color: #f3fbff;\n  border-radius: 14px;\n  padding: 10px;\n  margin-bottom: 10px;\n  cursor: pointer;\n  border: 1px solid transparent;\n}\n\n.rounded-container:not(.selected):hover {\n  border: 1px solid #76d0f4;\n  background-color: #e5f5fd;\n}\n\n.selected {\n  background-color: #76D0F4; \n  color: #FFFFFF; \n}\n\n.corner {\n  width: 50%; /* Each corner takes 50% width */\n  border-radius: 14px;\n}\n\n.top-left {\n  border-top-left-radius: 14px;\n}\n\n.top-right {\n  border-top-right-radius: 14px;\n  text-align: end;\n}\n\n.margin-bottom-15 {\n  margin-bottom: 15px !important;\n}\n\n.accText {\n  font-size: 19px;\n  font-weight: bold;\n  color: #ee4751;\n}\n\n.textBold {\n  font-weight: bold;\n}\n\n.containerData{\n  position: absolute;\n  top: 40%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.componentHeight {\n  height: calc(100vh - 164px);\n}\n\n.logiComponentHeight {\n  height: 750px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
