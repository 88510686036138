import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { AuthService } from '@services';
import { Dict, isNil, to, __DEV__, get, getError } from '@utils';

@Component({
	selector: 'billing-address',
	template: `
		<stack direction="['column', null, 'row']" spacing="4">
			<h5 sx variant="title" w="72">Default Billing Address</h5>

			<card sx flex="1">
				<!-- alert ‣ overall changes or errors -->
				<alert #alertRef [isOpen]="error | isNotNil" severity="error" variant="filled">
					{{ error }}
				</alert>

				<!-- forms -->
				<card-content>
					<address
						#address
						[edit]="editMode"
						[isLoading]="isLoading"
						initialFocus="name"
						format="billing"
					></address>
				</card-content>

				<!-- actions -->
				<card-actions hasDivider="true" align="flex-start">
					<!-- edit address -->
					<button
						*ngIf="!editMode"
						(click)="toggleEditMode()"
						[disabled]="isLoading"
						class="btn btn-default"
						sx
						variant="button.text button.contentIsSpaced"
					>
						<icon
							colorScheme="inherit"
							icon="{{
								isLoading
									? 'not_listed_location'
									: isPreloaded
									? 'edit_location'
									: 'add_location_alt'
							}}"
						></icon>
						<span>
							{{
								isLoading ? 'Loading...' : isPreloaded ? 'Edit address' : 'Add address'
							}}
						</span>
					</button>

					<!-- submit edit/cancel -->
					<h-stack>
						<button
							*ngIf="editMode"
							(click)="handleSubmit(addressRef.value)"
							[disabled]="isUpdating || !address.valid || !address?.changed"
							class="btn btn-primary"
							sx
							variant="button.contained button.contentIsSpaced"
						>
							<span *ngIf="!isUpdating">Update</span>
							<mat-spinner *ngIf="isUpdating" diameter="24"></mat-spinner>
						</button>
						<button *ngIf="editMode" (click)="handleCancel()" class="btn btn-default">
							Cancel
						</button>
					</h-stack>
				</card-actions>
			</card>
		</stack>
	`,
})
export class BillingAddress {
	error: string;

	editMode: boolean = false;

	isLoading: boolean = true;

	isPreloaded: boolean = false;

	isUpdating: boolean = false;

	isValid: boolean = false;

	/* ---------------------------------- */

	@ViewChild('address', { static: true }) addressRef: any;

	/* ---------------------------------- */

	constructor(protected auth: AuthService, protected cdr: ChangeDetectorRef) {
		// If this is a dev environment, enhance the dev ergonomics by making global var available.
		if (__DEV__) {
			if (isNil(window['billingAddress'])) window['billingAddress'] = this;
		}
	}

	async ngOnInit() {
		const [err, address] = await to(this.auth.getDefaultAddress());
		if (!err && address) {
			this.addressRef.patchValue(address);
			this.isPreloaded = true;
		}
		this.isLoading = false;
		return this.cdr.detectChanges();
	}

	_clearErrors = () => {
		this.error = null;
		return this.cdr.detectChanges();
	};

	/* ---------------------------------- */

	toggleEditMode = () => {
		this.editMode = !this.editMode;
	};

	handleCancel = () => {
		this.addressRef.reset();
		this.toggleEditMode();
	};

	handleSubmit = async (addressValue: Dict) => {
		this._clearErrors();
		this.isUpdating = true;
		this.addressRef.disable();

		const [err] = this.isPreloaded
			? await to(this.auth.updateBillingAddress(addressValue))
			: await to(this.auth.createBillingAddress(addressValue));

		this.isUpdating = false;
		this.addressRef.enable();
		if (err) {
			this.error = getError(err);
		} else {
			this.addressRef.markAsPristine();
			this.toggleEditMode();
		}
		return this.cdr.detectChanges();
	};
}
