import { Component, OnInit } from '@angular/core';
import {
	AuthService,
	InvoiceService,
	StaticTextService,
	ConfigService,
} from '../../services';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { CONSTANTS } from '../../app.constants';
import * as momentTimeZone from 'moment-timezone';
import { MatDialog } from '@angular/material/dialog';
import { InvoiceServiceV2 } from './invoice.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Title } from '@angular/platform-browser';

const INVOICE_STATES = {
	PAST_DUE:'Past Due',
	CURRENT_DUE:'Current Due',
	NOT_DUE:'Not Due Yet'
}

@Component({
	selector: 'app-invoice_v2',
	templateUrl: './invoice.component.html',
	styleUrls: ['./invoice.component.scss'],
})
export class InvoiceComponentV2 implements OnInit {
	filterBy = {
		Customer_ID: null,
		Organization_ID:null,
		fromDate: null,
		toDate: null,
	};
	errors = {
		Date_Range: null,
		date: null,
	};
	orders = [];
	invoices = [];
	pastDueInvoices = [];
	currentDueInvoices = [];
	notDueInvoices = []
	clickedRows = new Set();
	tableColumns = ['select', 'invoiceDate', 'dueDate', 'billTo', 'total', 'status'];
	creatingCheckoutSession = false;
	selection: any;
	address = '';
	total = 0;
	availabledates = [];
	pdfFilter: any = {};
	selectedPeriod: any;
	maxFromDate: Date;
	maxToDate: Date;
	clientAddress: any = {};
	timer: any;
	sortEvent:any=null;

	constructor(
		private auth: AuthService,
		private invoiceService: InvoiceService,
		private invoiceServiceV2: InvoiceServiceV2,
		public text: StaticTextService,
		private router: Router,
		private matDialog: MatDialog,
		private config: ConfigService,
		private titleService: Title,
	) {}

	ngOnInit() {
		this.titleService.setTitle(`Pippin Title™ - Client - Invoices`)
		this.auth.getHubSpotTokenDetails();
		this.auth.loadHubSpotChatToken();
		this.filterBy.Customer_ID = this.auth.getUserId();
		this.filterBy.Organization_ID = this.auth.orgId
		this.getClientAddress();
		this.getCompletedOrderSpan();
        this.getDueInvoicesData()
		this.selection = new SelectionModel<any>(true, []);
		this.timer = setTimeout(() => this.config.setSideBarNode(6), 0);
	}

	ngOnDestroy() {
		if (this.timer) clearTimeout(this.timer);
	}

	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.invoices.filter(e=>e.status !=='Paid' ).length;
		return numSelected == numRows;
	}

	toggleAllRows() {
		this.isAllSelected()
			? this.selection.clear()
			: this.invoices.forEach(invoice => invoice.status !=='Paid' &&  this.selection.select(invoice));
	}

	handlePaySelectedInvoices() {
		this.creatingCheckoutSession = true;
		const selected = this.selection.selected;
		this.invoiceServiceV2.paySelectedInvoices(selected).subscribe(({ url }) => {
			this.creatingCheckoutSession = false;
			window.location.href = url;
		});
	}

	validateDateRange() {
		if (
			this.filterBy.fromDate &&
			this.filterBy.toDate &&
			this.filterBy.fromDate > this.filterBy.toDate
		) {
			return false;
		} else {
			return true;
		}
	}

	setEvalperiod(event) {
		this.orders = [];
		
		if (event != -1 && event.year && event.month) {
			const {start ,end} = this.getStartAndEndOfMonth(event.year, event.month)
			this.filterBy.fromDate = start;
			this.filterBy.toDate = end;
		    this.getInvoiceOrdersV2();
			return 
		}
		this.selectedPeriod = event;
		if(event === INVOICE_STATES.PAST_DUE){
			this.getPastDueInvoicesData(true)
			
		}

		if(event === INVOICE_STATES.CURRENT_DUE ){
			this.getCurrentDueInvoicesData(true)
		}

		if(event === INVOICE_STATES.NOT_DUE ){
			this.getNotDueYetInvoicesData(true)
		}
		
	}

	getlastComplMonth() {
		var last = moment().subtract(0, 'months');
		return {
			moment: last,
			month: last.format('MMMM'),
			year: last.year(),
		};
	}

	validateDateFn(event) {
		var formattedDate = moment(event).format('L');
		//var DATE_REGEXP = /(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)\d{2}/; //for mm/dd/yyyy
		var DATE_REGEXP = /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/[12][0-9]{3}$/; //for m/d/yyyy and mm/dd/yyyy
		if (formattedDate && !DATE_REGEXP.test(formattedDate)) this.errors['date'] = true;
		else delete this.errors['date'];
	}

	getInvoiceOrdersV2() {
		if (!this.validateDateRange()) {
			this.errors.Date_Range = true;
			this.orders = [];
		} else {
			delete this.errors.Date_Range;
			if (this.filterBy.fromDate && this.filterBy.toDate && !this.errors.date) {
				this.pdfFilter = Object.assign({}, this.filterBy);
				this.invoiceServiceV2.getInvoiceOrders(this.filterBy).subscribe(res => {
					console.log({ res });
					this.invoices = res;
				});
			}
		}
	}

	getPastDueInvoicesData(overrideTableInvoices = false){
		return this.invoiceServiceV2.getPastDueInvoices(this.filterBy).subscribe(pastDue => {
			this.pastDueInvoices = pastDue;
			if(overrideTableInvoices) {
				this.invoices = pastDue
			}
		});
	}

	getCurrentDueInvoicesData(overrideTableInvoices= false){
		return this.invoiceServiceV2.getCurrentDueInvoices(this.filterBy).subscribe(currDue => {
			this.currentDueInvoices = currDue;
			if(overrideTableInvoices) {
				this.invoices = currDue
			}
		});
	}

	getNotDueYetInvoicesData(overrideTableInvoices = false){
		 this.invoiceServiceV2.getNotDueInvoices(this.filterBy).subscribe(notDue => {
			this.notDueInvoices = notDue;
			if(overrideTableInvoices) {
				this.invoices = notDue
			}
		});
	}

	getDueInvoicesData(){
		this.getPastDueInvoicesData()
		this.getCurrentDueInvoicesData()
		this.getNotDueYetInvoicesData()
	}

	getClientAddress() {
		this.auth.getProfile().subscribe(
			data => {
				this.clientAddress = data;
				this.buildAddress(data);
			},
			err => {
				this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
				this.auth.logout();
				this.router.navigate(['/login']);
			},
		);
	}

	buildAddress(model) {
		this.address = '';
		if (model.Address_1) this.address += model.Address_1;
		if (model.Address_2) {
			if (this.address) this.address += ' ';
			this.address += model.Address_2;
		}
		if (model.Address_City) {
			if (this.address) this.address += ', ';
			this.address += model.Address_City;
		}
		if (model.Address_State_Abbr) {
			if (this.address) this.address += ', ';
			this.address += model.Address_State_Abbr;
		}
		if (model.Address_ZipCode) {
			if (this.address) this.address += ' ';
			this.address += model.Address_ZipCode;
		}
	}

	calculateTotal(orders) {
		this.total = 0;
		orders.forEach(order => {
			this.total += Number(order.Order_Custom_Price);
			this.total += Number(order.Order_Custom_Price_Tax);
		});
	}

	openErrorPopup(msg) {
		let dialogRef = this.matDialog.open(ErrorDialogComponent, {
			data: {
				message: msg,
			},
		});
	}

	getCompletedOrderSpan() {
		this.invoiceService.getInvoiceSpan().subscribe(res => {
			this.generateDateSpan(res, this.getlastComplMonth());
		});
	}

	generateDateSpan(start, end) {

		if (start) {
			var startMoment = moment(start);
			var endMoment = end.moment;

			while (endMoment.isSameOrAfter(startMoment, 'month')) {
				this.availabledates.push({
					moment: endMoment,
					month: endMoment.format('MMMM'),
					year: endMoment.year(),
					displayText : `${endMoment.format('MMMM')} ${endMoment.year()}`
				});
				endMoment.subtract(1, 'month');
			}
			if (!this.availabledates.length) this.availabledates.push(end);
		} else this.availabledates.push(end);
		this.selectedPeriod = this.availabledates[0];
		this.filterBy.fromDate = moment()
			.year(this.selectedPeriod.year)
			.month(this.selectedPeriod.month)
			.startOf('month')
			.toDate();
		this.filterBy.toDate = moment()
			.year(this.selectedPeriod.year)
			.month(this.selectedPeriod.month)
			.endOf('month')
			.toDate();
		this.getInvoiceOrdersV2();
	}

	savePDF() {
		this.invoiceService.generatePDF(this.pdfFilter).subscribe(userFile => {
			var blob = new Blob(this.getByteArray(userFile), {
				type: 'application/octet-stream',
			});
			var link = document.createElement('a');
			document.body.appendChild(link);
			link.download = 'invoice.pdf';
			link.href = window.URL.createObjectURL(blob);
			link.click();
			document.body.removeChild(link);
		});
	}

	getByteArray(file) {
		var sliceSize = 512;

		var byteCharacters = atob(file);
		var byteArrays = [];

		for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			var slice = byteCharacters.slice(offset, offset + sliceSize);

			var byteNumbers = new Array(slice.length);
			for (var i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			var byteArray = new Uint8Array(byteNumbers);

			byteArrays.push(byteArray);
		}
		return byteArrays;
	}

	validateAndresetToDate(event) {
		this.validateDateFn(event);
		this.filterBy.toDate = null;
	}

	checkDaylight(etaDate) {
		if (momentTimeZone.tz(etaDate, 'America/New_York').isDST()) return 'EDT';
		else return 'EST';
	}

	getStartAndEndOfMonth(year, monthName) {
		const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
		const month = monthNames.indexOf(monthName);
		const startOfMonth = new Date(year, month, 1);
		const endOfMonth = new Date(year, month + 1, 0);
	  
		const formatDate = (date) => {
		  let d = new Date(date),
			  month = '' + (d.getMonth() + 1),
			  day = '' + d.getDate(),
			  year = d.getFullYear();
	  
		  if (month.length < 2) 
			  month = '0' + month;
		  if (day.length < 2) 
			  day = '0' + day;
	  
		  return [year, month, day].join('-');
		};
	  
		const formattedStartOfMonth = formatDate(startOfMonth);
		const formattedEndOfMonth = formatDate(endOfMonth);
	  
		return { start: formattedStartOfMonth, end: formattedEndOfMonth };
	  }

}
