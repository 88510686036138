import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ReactiveFormsModule, FormsModule, UntypedFormGroup, FormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { AuthService, StaticTextService, NewOrderService, ConfigService, PricingService } from '../../services';
import { DialogService } from "../../module/dialog/bootstrap-modal.module";
import { SuccessComponent } from '../../dialog/success/success.component';
import { MatDialog } from '@angular/material/dialog';

declare var google: any;

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {

  editAddressFrm: UntypedFormGroup;
  error: string = '';
  errorType: string = '';
  errorMsg: any = {};
  editAddressModel: any = {};
  userData: any = {};
  latitute: any;
  longitude: any;
  states = [];
  timer:any;
  constructor(
    private router: Router,
    private frmBuilder: UntypedFormBuilder,
    private config:ConfigService,
    public text: StaticTextService,
    private newOrder: NewOrderService,
    private auth: AuthService,
    private ref: ChangeDetectorRef,
    // private dialogService: DialogService,
    private matdialog:MatDialog,
    private pricingService: PricingService
  ) {
    this.editAddressFrm = frmBuilder.group({
      'locality': [null, Validators.compose([Validators.required, Validators.maxLength(127)])],
      'city': [null, Validators.compose([Validators.required, Validators.maxLength(38)])],
      'state': [null],
      'zipCode': [null, Validators.compose([Validators.required, Validators.maxLength(5)])],
      'Address_2': [null, Validators.compose([Validators.maxLength(60)])],
    })
    this.editAddressFrm.valueChanges.subscribe((data) => {
      this.error = '';
    })
 }
  ngOnInit() {
    this.auth.getHubSpotTokenDetails();
		this.auth.loadHubSpotChatToken();
    this.loadData();
    this.pricingService.getAvailableStateList().subscribe((states) => { this.states = states });

    let searchBox: any = document.getElementById('search-box');
    let options = {
      types: [
        // return only geocoding results, rather than business results.
        'geocode',
      ]
    };
    var autocomplete = new google.maps.places.Autocomplete(searchBox, options);
    //
    // Add listener to the place changed event
    autocomplete.addListener('place_changed', () => {
      this.getPlaceDetails(autocomplete.getPlace());
      this.ref.detectChanges();
    });
    this.timer=setTimeout(() => this.config.setSideBarNode(0), 0);
  }
  updateCompanyName() {
    this.userData['User_ID'] = this.auth.getUserId();
    this.userData['Address_1'] = this.editAddressModel['locality'];
    this.userData['Address_City'] = this.editAddressModel['city'];
    this.userData['Address_State_Abbr'] = this.editAddressModel['state'];
    this.userData['Address_ZipCode'] = this.editAddressModel['zipCode'];
    this.userData['Address_2'] = this.editAddressModel['Address_2'];

    this.auth.updateAddress(this.userData)
      .subscribe((res) => {
        this.openDialog()
        this.router.navigate(['user/' + this.auth.getUserId() + '/profile']);
      }, (error) => {
        this.errorMsg = error;
        this.errorType = error.type;
      });
  }

  getPlaceDetails(place: any) {
    this.editAddressModel.locality = '';
    this.editAddressModel.Address_1 = '';
    this.editAddressModel.Address_2 = '';
    this.editAddressModel.city = '';
    this.editAddressModel.state = '';
    this.editAddressModel.zipCode = '';
    
    if (place.geometry == undefined) {
      //set an error - show the error under google search line
      // a custom validator need to be defined and an error message "Place not found" need to be shown
      return;
    } else {

    }
    this.latitute = place.geometry.location.lat();
    this.longitude = place.geometry.location.lng();
    if (place.address_components.length) {
      for (let i = 0; i < place.address_components.length; i++) {
        switch (place.address_components[i].types[0]) {
          case 'street_number': this.editAddressModel.locality = place.address_components[i].long_name + ' ';
            break;
          case 'route': this.editAddressModel.locality = this.editAddressModel.locality + place.address_components[i].long_name;
            break;
          case 'locality': {
            //if(this.editAddressModel.city.length==0) 
            this.editAddressModel.city = place.address_components[i].long_name;
            break;
          }
          case 'administrative_area_level_2': {
            //if (this.editAddressModel.city.length == 0)
            //this.editAddressModel.city = place.address_components[i].long_name;
            break;
          }
          case 'administrative_area_level_1': this.editAddressModel.state = place.address_components[i].short_name;
            break;
          case 'postal_code': this.editAddressModel.zipCode = place.address_components[i].short_name;
            break;
          default: break;
        }
      }
    } else {
      this.editAddressModel.locality = '';
    }
  }


  goToAccountInfo() {
    this.router.navigate(['user/' + this.auth.getUserId() + '/profile']);
  }

  goToNewOrder() {
    this.newOrder.goToNewOrder()
  }

  openDialog() {

    let dialogRef= this.matdialog.open(SuccessComponent,{
      data:{
        title: this.text.get('LBL32') + ' ' + this.text.get('LBL33'),
        message: this.text.get('TXT28')
      }
    })

   

    // let disposable = this.dialogService.addDialog(SuccessComponent, {
    //   title: this.text.get('LBL32') + ' ' + this.text.get('LBL33'),
    //   message: this.text.get('TXT28')
    // })
    //   .subscribe((res) => {

    //   });
  }

  loadData() {
    this.auth.getProfile()
      .subscribe((data) => {

        if (data) {
          this.editAddressModel['locality'] = data.Address_1;
          if (this.editAddressModel['locality']) {
          }
          this.editAddressModel['city'] = data.Address_City;
          if (this.editAddressModel['city']) {
          }
          this.editAddressModel['state'] = data.Address_State_Abbr;
          if (this.editAddressModel['state']) {
          }
          this.editAddressModel['zipCode'] = data.Address_ZipCode;
          if (this.editAddressModel['zipCode']) {
          }
          this.editAddressModel['Address_2'] = data.Address_2;
          if (this.editAddressModel['Address_2']) {
          }
        }
      }, (err) => {

      })
  }

  clearZipcode() {
    this.editAddressFrm.controls['zipCode'].reset();
  }

  ngOnDestroy(){
    if(this.timer) clearTimeout(this.timer);
  }
}
