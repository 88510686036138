import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


@Injectable()
export class AuthGuardService  {

  constructor(
    private router: Router,
    private auth: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.auth.canActivate()
      .pipe(map((response) => { return true; }),
        catchError((error) => {
          this.auth.logout();
          this.auth.setRedirectURL(state.url);
          this.router.navigate(['/login']);
          return new Promise((resolve, reject) => resolve(error));
        }),
    )


  }

}
