import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, ConfigService } from '../../services';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable()
export class InvoiceServiceV2 {
	constructor(
		private auth: AuthService,
		private config: ConfigService,
		private http: HttpClient,
	) {}

	getInvoiceOrders(filterBy) {
		const httpOptions = {
			headers: new HttpHeaders({ Authorization: 'Bearer ' + this.auth.getToken() }),
		};
		let url = this.config.getBasePath() + '/v2/invoice/getInvoices';
		return this.http.post(url, filterBy, httpOptions).pipe(
			map((response: any) => response),
			catchError((error: any) => observableThrowError(error.error)),
		);
	}

	getPastDueInvoices(filterBy) {
		const httpOptions = {
			headers: new HttpHeaders({ Authorization: 'Bearer ' + this.auth.getToken() }),
		};
		let url = this.config.getBasePath() + '/v2/invoice/getPastDueInvoices';
		return this.http.post(url, filterBy, httpOptions).pipe(
			map((response: any) => response),
			catchError((error: any) => observableThrowError(error.error)),
		);
	}

	getCurrentDueInvoices(filterBy) {
		const httpOptions = {
			headers: new HttpHeaders({ Authorization: 'Bearer ' + this.auth.getToken() }),
		};
		let url = this.config.getBasePath() + '/v2/invoice/getCurrentDueInvoices';
		return this.http.post(url, filterBy, httpOptions).pipe(
			map((response: any) => response),
			catchError((error: any) => observableThrowError(error.error)),
		);
	}

	getNotDueInvoices(filterBy) {
		const httpOptions = {
			headers: new HttpHeaders({ Authorization: 'Bearer ' + this.auth.getToken() }),
		};
		let url = this.config.getBasePath() + '/v2/invoice/getNotDueInvoices';
		return this.http.post(url, filterBy, httpOptions).pipe(
			map((response: any) => response),
			catchError((error: any) => observableThrowError(error.error)),
		);
	}

	paySelectedInvoices(invoices) {
		const httpOptions = {
			headers: new HttpHeaders({ Authorization: 'Bearer ' + this.auth.getToken() }),
		};
		let url = this.config.getBasePath() + '/v2/invoice/payInvoices';
		console.log({ url, invoices });
		return this.http.post(url, invoices, httpOptions).pipe(
			map((response: any) => response),
			catchError((error: any) => observableThrowError(error.error)),
		);
	}
}
