import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { PasswordvalidatorService } from '@services';

@Component({
  selector: 'app-password-validator',
  templateUrl: './password-validator.component.html',
  styleUrl: './password-validator.component.scss'
})
export class PasswordValidatorComponent  {
  @Input() password:string;
  errorList:any[]=[];

  constructor(public validator:PasswordvalidatorService){
   
  }

  ngOnChanges(changes: SimpleChanges) {
    this.errorList=this.validator.validatePassword(changes.password.currentValue);
}
  

  

}
