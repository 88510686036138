import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Inject,
	OnDestroy,
	ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService, NewOrderService } from '../../services';

@Component({
	selector: 'app-stripe-payment',
	templateUrl: './stripe-payment.component.html',
	styleUrls: ['./stripe-payment.component.scss'],
})
export class StripePaymentComponent implements OnDestroy, AfterViewInit {
	_totalAmount: number;
	_incidentalCostInfoMessage: string = 'Incidental cost';
	_billingAddress = null;

	constructor(
		private cd: ChangeDetectorRef,
		private orderService: NewOrderService,
		@Inject(MAT_DIALOG_DATA) private data: any,
		private dialogRef: MatDialogRef<StripePaymentComponent>,
		private auth: AuthService,
	) {
		this._totalAmount = data['totalAmount'];
		this._billingAddress = data['billingAddress'];
	}

	@ViewChild('cardInfo', { static: true }) cardInfo: ElementRef;

	card: any;
	cardHandler = this.onChange.bind(this);
	cardError: string;

	ngOnDestroy() {
		if (this.card) {
			// We remove event listener here to avoid memory leaks
			this.card.removeEventListener('change', this.cardHandler);
			this.card.destroy();
		}
	}

	ngAfterViewInit() {
		this.initiateCardElement();
	}

	/**
	 * Create a card element using Stripe Elements and mounts it to 'this.card'.
	 */
	initiateCardElement() {
		// Giving a base style here, but most of the style is in scss file
		const cardStyle = {
			base: {
				color: '#32325d',
				fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
				fontSmoothing: 'antialiased',
				fontSize: '16px',
				'::placeholder': {
					color: '#aab7c4',
				},
			},
			invalid: {
				color: '#fa755a',
				iconColor: '#fa755a',
			},
		};
		// const stripe = Stripe(environment.stripePublishKey)
		// const elements = stripe.elements();
		this.card = elements.create('card', { cardStyle });
		this.card.mount(this.cardInfo.nativeElement);

		this.card.addEventListener('change', this.cardHandler);
	}

	/**
	 * Takes error attribute from object given and set it's value to 'this.cardError'
	 *
	 * @param error
	 */
	onChange({ error }) {
		if (error) this.cardError = error.message;
		else this.cardError = null;

		this.cd.detectChanges();
		// using ChangeDetector to detect changes for 'this.cardError'
	}

	/**
	 * Creates two tokens for the same card and calls onError or onSuccess methods.
	 * We create two tokens because of main order payment and incidental cost payment is done
	 * separately.
	 * Since Stripe tokens are single use, back-end needs two tokens to crate those payments.
	 */
	createStripeToken() {
		const billingData = this.getBillingAddress();
		const promiseCollection: any[] = [];
		if (billingData) {
			promiseCollection.push(stripe.createToken(this.card, billingData));
			promiseCollection.push(stripe.createToken(this.card, billingData));
		} else {
			promiseCollection.push(stripe.createToken(this.card));
			promiseCollection.push(stripe.createToken(this.card));
		}
		Promise.all(promiseCollection)
			.then((result: any[]) => {
				const firstToken = result[0];
				const secondToken = result[1];
				if (firstToken.error || secondToken.error) {
					this.onError(firstToken.error ? firstToken.error : secondToken.error);
				} else {
					this.onSuccess(firstToken.token, secondToken.token);
				}
			})
			.catch(() => {});
	}

	getBillingAddress(): StripeBillingAddress {
		let billingAddress = null;
		if (this._billingAddress) {
			billingAddress = this._billingAddress;
		} else if (this.orderService.billingData) {
			billingAddress = this.orderService.billingData;
		}
		const customerFullName: string = this.auth.getName();
		if (billingAddress) {
			return {
				name: customerFullName,
				address_line1: billingAddress['Billing_Street_Address_Line_1'],
				address_line2: billingAddress['Billing_Street_Address_Line_2'],
				address_city: billingAddress['Billing_City'],
				address_state: billingAddress['Billing_State'],
				address_zip: billingAddress['Billing_Zip'],
				address_country: 'US',
			};
		} else {
			return null;
		}
	}

	/**
	 * Takes two tokens and closes the dialog with two tokens as result.
	 *
	 * @param token            Token object from stripe
	 * @param secondToken      Token object from stripe
	 */
	onSuccess(token, secondToken) {
		this.dialogRef.close({ token, secondToken });
	}

	/**
	 * Takes an error and set's the 'error.message' value to 'this.cardError'
	 *
	 * @param error        Error object from Stripe
	 */
	onError(error) {
		if (error.message) this.cardError = error.message;
	}
}

interface StripeBillingAddress {
	name: string;
	address_line1: string;
	address_line2: string;
	address_city: string;
	address_state: string;
	address_zip: string;
	address_country: string;
}
