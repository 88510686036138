// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forget {
  position: absolute;
  font-size: 0.75rem;
  bottom: 2.5rem;
  left: 1rem;
  text-decoration: none;
  transition: color 0.2s;
}

.sign-up {
  font-weight: 600;
  text-shadow: 0px 0px 1px rgb(170, 170, 170);
}

.padding {
  padding-left: 20px;
}

.changepass {
  position: absolute;
  font-size: 0.75rem;
  bottom: 8.5rem;
  left: 13.5rem;
  text-decoration: none;
  transition: color 0.2s;
}

.changepass1 {
  position: relative;
  font-size: 0.75rem;
  bottom: 0.9rem;
  left: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/authentication/login/login.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,kBAAA;EACA,cAAA;EACA,UAAA;EACA,qBAAA;EACA,sBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,2CAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,kBAAA;EACA,cAAA;EACA,aAAA;EACA,qBAAA;EACA,sBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,kBAAA;EACA,cAAA;EACA,YAAA;AACJ","sourcesContent":[".forget {\n    position: absolute;\n    font-size: 0.75rem;\n    bottom: 2.5rem;\n    left: 1rem;\n    text-decoration: none;\n    transition: color 0.2s;\n}\n\n.sign-up {\n    font-weight: 600;\n    text-shadow: 0px 0px 1px rgb(170, 170, 170);\n}\n\n.padding{\n    padding-left: 20px;\n}\n\n.changepass {\n    position: absolute;\n    font-size: 0.75rem;\n    bottom: 8.5rem;\n    left: 13.5rem;\n    text-decoration: none;\n    transition: color 0.2s;\n}\n\n.changepass1 {\n    position: relative;\n    font-size: 0.75rem;\n    bottom: 0.9rem;\n    left: 0.5rem;\n}  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
