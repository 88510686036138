
import {forkJoin as observableForkJoin,  Observable, Subject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, StaticTextService, NewOrderService, OrderListingService, PagerService, ConfigService, StateService, ScrollService } from '../../services';
import { PreloaderService } from '../../services/preloader/preloader.service'
import { DialogService } from "../../module/dialog/bootstrap-modal.module";
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { CONSTANTS } from '../../app.constants'
import * as momentTimeZone from 'moment-timezone';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent implements OnInit {
  displayedColumns: string[] = ['Titles_ID','File_ID','Property_First_Name','Property_Address_1','Property_State_Abbr','Property_County','Order_Status',	'Product_Description','Order_Custom_Price',	'Order_Creation_Date'];
  dataSource= new MatTableDataSource<any>([]);
  sortEvent:any=null;

  constants = CONSTANTS;
  orders: any = []
  orderStatus: any = {};
  ordersToDisplay: any;
  totalCount: number;
  pager: any = {};
  searchString: string;
  searchInProgress: boolean = false;
  sortField: string;
  orderBy: string;
  pageNum: number;
  listingType = 'my'
  filterBy: any = [];
  timer:any= [];
  scrollEnable: boolean = true;
  srollDistance=this.config.setScrollDistance();
  srollThrottle=this.config.setScrollThrottle();
  searchQuery = new Subject<string>();
  hasScrollDown:boolean=false;
  
  constructor(
    private auth: AuthService,
    private router: Router,
    public text: StaticTextService,
    private newOrder: NewOrderService,
    private orderList: OrderListingService,
    private pagerService: PagerService,
    private config: ConfigService,
    private preloaderService: PreloaderService,
    private stateService: StateService,
    private matDialog:MatDialog,
    private scrollService: ScrollService,
    private titleService: Title,
  ) {
    this.pager.pages = [];
    if(this.pageNum == undefined)
    this.pageNum = 1;
    this.orderBy = 'DESC';
    this.sortField = 'Order_Creation_Date';
    this.searchString = '';
    this.searchQuery.pipe(
      debounceTime(this.config.getDebounceTime()),
      distinctUntilChanged())
      .subscribe(value => this.searchField(value));
  }

  ngOnInit() {
    this.titleService.setTitle(`Pippin Title™ - Client - Orders (Completed)`)
    this.auth.getHubSpotTokenDetails();
		this.auth.loadHubSpotChatToken();
    this.orderStatus = this.orderList.getOrderStatus();
    if (this.stateService.getCompletedOrders()) {
      this.initializeComponentData(this.stateService.getCompletedOrders());
    }
    else {
      this.checkListingType();
      this.getOrders();
    }
    this.timer.push(setTimeout(() => this.config.setSideBarNode(2), 0));
  }

  goToNewOrder() {
    this.newOrder.goToNewOrder()
  }

  checkListingType() {
    if (!this.auth.isIndividualUser()) {
      if (this.listingType == 'my') this.filterBy.push({ Customer_ID: this.auth.getUserId() });
      else this.filterBy.pop('Customer_ID');
    }
  }

  // setPage(page: number) {
  //   
  //   if (page < 1 || page > this.pager.totalPages) {
  //     return;
  //   }
  //   this.pageNum = page;
  //   this.setDisplayOrders();
  //   this.getOrders();
  //   this.pager = this.pagerService.getPager(this.totalCount, this.pageNum);
  //   
  // }

  setSort(field: string) {
    this.pageNum = 1;
    if (field == this.sortField) {
      if (this.orderBy == 'ASC') this.orderBy = 'DESC';
      else this.orderBy = 'ASC';
    } else {
      this.sortField = field;
      this.orderBy = 'ASC';
    }
    this.getOrders();
  }

  setDisplayOrders() {
    let start = ((this.pageNum - 1) * this.config.getNumRecordsPerPage());
    this.ordersToDisplay = this.orders.slice(start, this.totalCount);
    if (this.ordersToDisplay.length > this.config.getNumRecordsPerPage()) this.ordersToDisplay = this.ordersToDisplay.slice(0, this.config.getNumRecordsPerPage());
  }

  getOrders() {
    if(this.pageNum == 1){
      this.stateService.setCompletedOrders(this);
      this.orderList.getOrderHistory(this.pageNum, this.sortField, this.orderBy, this.getSearchString(), this.filterBy)
        .subscribe((result) => {
          if (result) {
            this.orders = result.rows;
            this.totalCount = result.count;
            this.getOrdRecordsData(this.totalCount);
            this.getScrollPosData();
            this.dataSource.data=this.orders;
          }
        }, (err) => {
          this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
          // this.auth.logout();
          // this.router.navigate(['/login']);
        })
    }else this.getRetainData();

  }

  getRetainData(){
    var ObsCollection = [];
    for(var i=1; i<=this.pageNum; i++){
      ObsCollection.push(this.orderList.getOrderHistory(i, this.sortField, this.orderBy, this.getSearchString(), this.filterBy))
    }

    observableForkJoin(ObsCollection)
      .subscribe((retainedHistory) => {
        if(retainedHistory){
          var totalOrders = [];
          for(var i=0;i<retainedHistory.length; i++){
            this.stateService.setCompletedOrders(this);
            totalOrders = totalOrders.concat(retainedHistory[i]['rows']);
          }
          this.orders = totalOrders;
          this.totalCount = retainedHistory[0]['count'];
          this.getOrdRecordsData(this.totalCount);
          this.getScrollPosData();
          this.dataSource.data=this.orders;
        }
      })
  }

  // search(event) {
  //   this.searchString = event;
  //   var userComp = this;
  //   function waitForIt(curStr) {
  //     if (userComp.searchInProgress) {
  //       this.timer.push(setTimeout(function () { waitForIt(curStr) }, 100));
  //     } else {
  //       if (userComp.searchString == curStr) userComp.findOrders();
  //     };
  //   }
  //   waitForIt(this.searchString);
  // }

  searchField(search){
    this.scrollService.setScrollPosCompltdOrd(0)
    var format = /[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]/;
    search = search.trim();
    this.searchString = search;
    if(!format.test(this.searchString)&& this.searchString==" "){
      this.searchString='';
      this.getOrders();
    }else this.findOrders();
  }

  findOrders() {
    this.pageNum = 1;
    this.preloaderService.setSearchSpin();
    this.searchInProgress = true;
    this.getOrders();
  }
  detectTypeChange(event) {
    this.pageNum = 1;
    document.documentElement.scrollTop = 0
    var x = document.documentElement.scrollTop
    this.scrollService.setScrollPosCompltdOrd(x);
    this.checkListingType();
    this.getOrders();
  }


  goToOrderDetails(orderId) {
    var getPos = document.documentElement.scrollTop;
    this.scrollService.setScrollPosCompltdOrd(getPos);
    this.router.navigate(['/orders/' + orderId + '/view'])
  }

  initializeComponentData(data) {
    this.pager = data.pager;
    this.pageNum = data.pageNum;
    this.searchString = data.searchString;
    this.orderBy = data.orderBy;
    this.sortField = data.sortField;
    this.totalCount = data.totalCount;
    this.ordersToDisplay = data.ordersToDisplay;
    this.searchInProgress = data.searchInProgress;
    this.listingType = data.listingType;
    this.checkListingType();
    this.getOrders();
  }

  openErrorPopup(msg) {
    let dialogRef=this.matDialog.open(ErrorDialogComponent,{
      data:{
        message: msg
      }
    });

    // let disposable = this.dialogService.addDialog(ErrorDialogComponent, {
    //   message: msg
    // }).subscribe((res) => { });
  }

  isIndividualUser() {
    return this.auth.isIndividualUser();
  }

  getSearchString() {
    var format = /[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]/;
    if (format.test(this.searchString)) {
      return '';
    } else return this.searchString;
  }
  handleEvent(event){
    this.scrollEnable = event;
  }

  onScrollDown() {
    this.config.setClearTimeout();
    this.config.setDelayRecords();
    this.onScrollData();
  }
  onTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  onScrollData() {
    if (this.pageNum < this.pager.totalPages) {
      this.pageNum++;
      this.orderList.getOrderHistory(this.pageNum, this.sortField, this.orderBy, this.getSearchString(), this.filterBy)
        .subscribe((result) => {
          if (result) {
            this.totalCount = result.count;
            this.orders = this.orders.concat(result.rows);
            this.pager = this.pagerService.getPager(this.totalCount, this.pageNum);
            this.dataSource.data=this.orders;
            if(this.sortEvent){
              this.onSortChange(this.sortEvent);
            }  
            this.hasScrollDown=false;
          }
        })
    }
  }

  getOrdRecordsData(result){
    this.config.setClearTimeout();
    this.setDisplayOrders();
    this.pager = this.pagerService.getPager(result, this.pageNum);
    this.searchInProgress = false;
    this.config.setDelayRecords();
  }

  getScrollPosData(){
    if(this.scrollService.getScrollPosCompltdOrd()){
      var x =this.scrollService.getScrollPosCompltdOrd();
      this.timer.push(setTimeout(function() {
        window.scrollTo(0,x);
      }));
    }
  }

  checkDaylight(etaDate) {
    if (momentTimeZone.tz(etaDate, 'America/New_York').isDST()) return 'EDT';
    else return 'EST'
  }

  ngOnDestroy(){
    if(this.timer){
      this.timer.forEach(time=>clearTimeout(time))
    }
  }

  onTableScroll(e) {
    // Check if the scroll position is at the 90% of height of the table
     if (!this.hasScrollDown && ((e.target.scrollTop + e.target.clientHeight)/(e.target.scrollHeight))>0.9){
        this.hasScrollDown=true;
        this.onScrollDown()
    }
}


onSortChange(e){
  this.sortEvent=e;
  this.dataSource.data = this.applySortingLogic([...this.dataSource.data], e);
}

applySortingLogic(data: any[], sortEvent: any): any[] {
  if (sortEvent.direction === '') {
    return this.orders;
  }
  const sortedData = data.sort((a, b) => {
    const isAsc = sortEvent.direction === 'asc';
    const column = sortEvent.active;

    if(!a[column] && !b[column]){
      return;
    }

    if(!a[column] || !b[column]){

      if(!a[column]){
        return isAsc ? "-".localeCompare(b[column]) : b[column].localeCompare("-");
      }else if(!b[column]){
        return isAsc ? a[column].localeCompare("-") : "-".localeCompare(a[column]);
      }
    }

    // console.log(a[column])
    if(typeof(a[column])=="number"){
      const comparisonResult = (a[column] > b[column]) ? 1 : ((a[column] < b[column]) ? -1 : 0);
      return isAsc ? comparisonResult : -comparisonResult;
    }
    
  
    return isAsc ? a[column].localeCompare(b[column]) : b[column].localeCompare(a[column]);
  });

  return sortedData;
}
}
