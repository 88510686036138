// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dottedLine {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 1px;
  background-repeat: repeat-x;
  position: relative;
  background-position-y: 24px;
  padding-bottom: 8px;
  cursor: default;
}`, "",{"version":3,"sources":["webpack://./src/app/common/address-comp/address-comp.component.scss"],"names":[],"mappings":"AAAA;EACI,4GAAA;EACA,wBAAA;EACA,2BAAA;EACA,kBAAA;EACA,2BAAA;EACA,mBAAA;EACA,eAAA;AACJ","sourcesContent":[".dottedLine{\n    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);\n    background-size: 4px 1px;\n    background-repeat: repeat-x;\n    position: relative;\n    background-position-y: 24px;\n    padding-bottom: 8px;\n    cursor: default;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
