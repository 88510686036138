// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.wrapper {
  padding-left: 0px;
  padding-right: 0px;
}

div#circle {
  width: 10px;
  height: 10px;
  /*background: rgb(15, 157, 88);  */
  /*background: rgb(3, 169, 244);*/
  display: block;
  border-radius: 50px;
  position: relative;
  top: -6px;
  transition: left 0.6s ease;
}

.block {
  display: inline-block;
}

.pb-labels {
  position: relative;
}

.progress {
  background-color: rgb(200, 200, 200);
  border-color: rgb(235, 235, 235);
  margin-bottom: 0px;
}`, "",{"version":3,"sources":["webpack://./src/app/common/progress-bar/progress-bar.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,kBAAA;AACJ;;AAEA;EACC,WAAA;EACA,YAAA;EACA,kCAAA;EACG,gCAAA;EACA,cAAA;EACH,mBAAA;EACG,kBAAA;EACA,SAAA;EACA,0BAAA;AACJ;;AAEA;EACI,qBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,oCAAA;EACA,gCAAA;EACA,kBAAA;AACJ","sourcesContent":["div.wrapper{\n    padding-left: 0px;\n    padding-right: 0px\n}\n\ndiv#circle {\n\twidth: 10px;\n\theight: 10px;\n\t/*background: rgb(15, 157, 88);  */\n    /*background: rgb(3, 169, 244);*/\n    display: block;\n\tborder-radius: 50px;\n    position: relative;\n    top: -6px; \n    transition: left 0.6s ease;   \n}\n\n.block{\n    display:inline-block;\n}\n\n.pb-labels{   \n    position: relative;\n}\n\n.progress{\n    background-color: rgb(200, 200, 200);\n    border-color: rgb(235, 235, 235);\n    margin-bottom:0px\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
