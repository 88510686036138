import { Component } from '@angular/core';

@Component({
  selector: 'app-payment-cancellation',
  standalone: true,
  imports: [],
  templateUrl: './payment-cancellation.component.html',
  styleUrl: './payment-cancellation.component.scss'
})
export class PaymentCancellationComponent {

}
