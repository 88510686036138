// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reqTime {
  margin-left: 18px !important;
  margin-bottom: 24px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/request-quote/request-quote3/request-quote3.component.scss"],"names":[],"mappings":"AAAA;EACI,4BAAA;EACC,8BAAA;AACL","sourcesContent":[".reqTime{\n    margin-left: 18px !important;\n     margin-bottom: 24px !important;\n }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
