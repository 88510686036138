import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PasswordvalidatorService {

  validatePassword(password: string): string[] {
    const errors: any[] = [];

    // Error 0: must have at least 8 characters
    if (password.length < 8) {
      errors.push({
        errorCode:0,
        error:'must have at least 8 characters!',
        status:true
      });
    }else{
      errors.push({
        errorCode:0,
        error:'must have at least 8 characters!',
        status:false
      });
    }

    // Error 1: must have at least 1 number
    if (!/\d/.test(password)) {
      errors.push({
        errorCode:1,
        error:'must have at least 1 number!',
        status:true
      });
    }else{
      errors.push({
        errorCode:0,
        error:'must have at least 8 characters!',
        status:false
      });
    }

    // Error 2: must have at least 1 capital case
    if (!/[A-Z]/.test(password)) {
      errors.push({
        errorCode:0,
        error:'must have at least 1 Capital Case!',
        status:true
      });
    }else{
      errors.push({
        errorCode:0,
        error:'must have at least 1 Capital Case!',
        status:false
      });
    }

    // Error 3: must have at least 1 small case
    if (!/[a-z]/.test(password)) {
      errors.push({
        errorCode:0,
        error:'must have at least 1 Small Case!',
        status:true
      });
    }else{
      errors.push({
        errorCode:0,
        error:'must have at least 1 Small Case!',
        status:false
      });
    }

    // Error 5: must have at least 1 special character
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      errors.push({
        errorCode:0,
        error:'must have at least 1 Special Character!',
        status:true
      });
    }else{
      errors.push({
        errorCode:0,
        error:'must have at least 1 Special Character!',
        status:false
      });
    }

    return errors;
  }

}
