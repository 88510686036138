export const CONSTANTS={
 userRole:{
    user:1,
    admin:2,
  }, 
  contactType:{
    mobile:'M',
    fax:'F'               
  },
  jwtOptions:{
    expiresIn: '1h'  //expires in an hour
  },
  propertyStatus:{
    active:1,
    inActive:0
  },
  orderPriority: {
    level1:0,
    level2:1
  },
  orderStatus:{
    received:1,
    // requestedPricing:5,
    cancelled:10,
    confirmed:20,
    clientconf: 18,
    processing: 30,
    assigned:15,
    quotecompleted:40,
    completed:50,
    forwarded:60,
    // accepted:90	,
    inActive:0
},
  categoryStatus:{
    active:1,
    inActive:0
  },
  orderDocumentStatus:{
    active:1,
    inActive:0
  },
  orderProductStatus:{
    active:1,
    inActive:0
  },
  orderProductCostStatus:{
    active:1,
    inActive:0
  },
  regionStatus:{
    active:1,
    inActive:0
  },
  stateTaxStatus:{
    active:1,
    inActive:0
  },
  stateStatus:{
    active:1,
    inActive:0
  },
  userStatus:{
    active:1,
    inActive:0,
    deActivated:99
  },
  emailPriority:{
    active:1,
    inActive:0
  },
  contactNumberPriority:{
    active:1,
    inActive:0
  },
  faxPriority:{
    active:1,
    inActive:0
  },
  passwordPriority:{
     active:1,
     second:2,
     third:3,
     fourth:4,
    inActive:0
  },
  verficationType:{
    emailCode:"Email_Code",
    smsCode:"SMS_Code",
    twofaCode:"2FA"
  },
  flagStatus:{
    active:1,
    inActive:0
  },
  orderDetailsStatus:{
    orderNotes:1,
    orderDesc:2,
    orderSI:4
  },
  orderInstructionStatus:{
    active:1,
    inActive:0
  },
  instructionType :{
    orderNotes:1,
    orderDesc:2,
    orderIns:4
  },
  messageType: {
    orderMessage: 1,
    directMessage:2
  },
  customCostStatus: {
    active: 1,
    inactive: 0
  },
  messageStatus : {
    unread :1,
    read:2
  },
  userType: {
    individual: 1,
    company: 2
  },
  orderState:{
    paused: 1,
    unpaused: 0
 },
 tagStatus :{
   typingTag : 'typing',
   completedTag: 'completed',
   qualityCheck: 'quality check',
   partiallyDelivered:'partially delivered',
   awaitingClarification:'awaiting clarification'
 },
  apiErrors:{
    docupload:'Document upload has failed. Please try again.',
    docdelete:'Unable to delete document. Please try again.',
    docuploadsize:'Document size is too large.',
    Docupload:'File size is too large.',
    sendmessage:'Messaging unsuccessful. Please try again.',
    docdownload:'Unable to download document. Please try again.',
    datafetch:'Error while fetching data. Please login and try again.',
    multiupload:'Error while uploading the documents. Please check and try again.',
    validateFile:'It seems like one/many files have invalid(filenames with comma) filenames. Please check and try again.',
    docunavailable:'No documents to download'
  },
  documentFileTypes:{
    fileFormat:".pdf,.doc,.docx,.rtf,.txt,.xlsx,.xls,.pptx,.ppt,.rar,.zip,.jpe,.jpg,.bmp,.gif,.png,.tif,.tiff,.gz"
  },
  // muiltiLineErr:{
  //   msg:"<ul class=" + "ulAlign" + ">" +
  //   "<b><li>Be at least 8 characters long</li></b>" +
  //   "<b ><li>Have both alpha and numeric characters</li></b>" +
  //   "<b><li>Have at least 1 special character(!@#$%^&*)</li></b>" +
  //   "<b><li>Have an uppercase and a lowercase character</li></b>" +
  //   "</ul>"
  // },
  saintArray: [
    'St. Clair',
    'St. Francis',
    'St. Joseph',
    'St. Bernard',
    'St. Charles',
    'St. Helena',
    'St. Landry',
    'St. Martin',
    'St. Tammany',
    'St. Clair',
    'St. Joseph',
    'St. Clair',
    'St. Charles',
    'St. Francois',
    'St. Genevieve',
    'St. Louis City',
    'St. Louis County',
    'St. Lawrence',
    'St. Croix'
  ],
  countyArray: [
    "Adair County"
    ,

    "Allen County"
    ,

    "Allen County"
    ,

    "Anderson County"
    ,

    "Anderson County"
    ,

    "Atchison County"
    ,

    "Ballard County"
    ,

    "Baltimore County"
    ,

    "Barber County"
    ,

    "Barren County"
    ,

    "Barton County"
    ,

    "Bath County"
    ,

    "Bell County"
    ,

    "Boone County"
    ,

    "Bourbon County"
    ,

    "Bourbon County"
    ,

    "Boyd County"
    ,

    "Boyle County"
    ,

    "Bracken County"
    ,

    "Breathitt County"
    ,

    "Breckenridge County"
    ,

    "Brown County"
    ,

    "Bullitt County"
    ,

    "Butler County"
    ,

    "Butler County"
    ,

    "Caldwell County"
    ,

    "Calloway County"
    ,

    "Campbell County"
    ,

    "Carlisle County"
    ,

    "Carroll County"
    ,

    "Carter County"
    ,

    "Casey County"
    ,

    "Chase County"
    ,

    "Chautauqua County"
    ,

    "Cherokee County"
    ,

    "Cheyenne County"
    ,

    "Christian County"
    ,

    "Clark County"
    ,

    "Clark County"
    ,

    "Clay County"
    ,

    "Clay County"
    ,

    "Clinton County"
    ,

    "Cloud County"
    ,

    "Coffey County"
    ,

    "Comanche County"
    ,

    "Cowley County"
    ,

    "Crawford County"
    ,

    "Crittenden County"
    ,

    "Cumberland County"
    ,

    "Daviess County"
    ,

    "Decatur County"
    ,

    "Dickinson County"
    ,

    "Doniphan County"
    ,

    "Douglas County"
    ,

    "Edmonson County"
    ,

    "Edwards County"
    ,

    "Elk County"
    ,

    "Elliott County"
    ,

    "Ellis County"
    ,

    "Ellsworth County"
    ,

    "Estill County"
    ,

    "Fayette County"
    ,

    "Finney County"
    ,

    "Fleming County"
    ,

    "Floyd County"
    ,

    "Ford County"
    ,

    "Franklin County"
    ,

    "Franklin County"
    ,

    "Fulton County"
    ,

    "Gallatin County"
    ,

    "Garrard County"
    ,

    "Geary County"
    ,

    "Gove County"
    ,

    "Graham County"
    ,

    "Grant County"
    ,

    "Grant County"
    ,

    "Graves County"
    ,

    "Gray County"
    ,

    "Grayson County"
    ,

    "Greeley County"
    ,

    "Green County"
    ,

    "Greenup County"
    ,

    "Greenwood County"
    ,

    "Hamilton County"
    ,

    "Hancock County"
    ,

    "Hardin County"
    ,

    "Harlan County"
    ,

    "Harper County"
    ,

    "Harrison County"
    ,

    "Hart County"
    ,

    "Harvey County"
    ,

    "Haskell County"
    ,

    "Hawaii County"
    ,

    "Henderson County"
    ,

    "Henry County"
    ,

    "Hickman County"
    ,

    "Hodgeman County"
    ,

    "Honolulu County"
    ,

    "Hopkins County"
    ,

    "Jackson County"
    ,

    "Jackson County"
    ,

    "Jefferson County"
    ,

    "Jefferson County"
    ,

    "Jessamine County"
    ,

    "Jewell County"
    ,

    "Johnson County"
    ,

    "Johnson County"
    ,

    "Kauai County"
    ,

    "Kearny County"
    ,

    "Kenton County"
    ,

    "Kingman County"
    ,

    "Kiowa County"
    ,

    "Knott County"
    ,

    "Knox County"
    ,

    "Labette County"
    ,

    "Lane County"
    ,

    "Larue County"
    ,

    "Laurel County"
    ,

    "Lawrence County"
    ,

    "Leavenworth County"
    ,

    "Lee County"
    ,

    "Leslie County"
    ,

    "Letcher County"
    ,

    "Lewis County"
    ,

    "Lincoln County"
    ,

    "Lincoln County"
    ,

    "Linn County"
    ,

    "Livingston County"
    ,

    "Logan County"
    ,

    "Logan County"
    ,

    "Lyon County"
    ,

    "Lyon County"
    ,

    "Madison County"
    ,

    "Magoffin County"
    ,

    "Marion County"
    ,

    "Marion County"
    ,

    "Marshall County"
    ,

    "Marshall County"
    ,

    "Martin County"
    ,

    "Mason County"
    ,

    "Maui County"
    ,

    "McCracken County"
    ,

    "McCreary County"
    ,

    "McLean County"
    ,

    "McPherson County"
    ,

    "Meade County"
    ,

    "Meade County"
    ,

    "Menifee County"
    ,

    "Mercer County"
    ,

    "Metcalfe County"
    ,

    "Miami County"
    ,

    "Mitchell County"
    ,

    "Monroe County"
    ,

    "Montgomery County"
    ,

    "Montgomery County"
    ,

    "Morgan County"
    ,

    "Morris County"
    ,

    "Morton County"
    ,

    "Muhlenberg County"
    ,

    "Nelson County"
    ,

    "Nemaha County"
    ,

    "Neosho County"
    ,

    "Ness County"
    ,

    "Nicholas County"
    ,

    "Norton County"
    ,

    "Ohio County"
    ,

    "Oldham County"
    ,

    "Osage County"
    ,

    "Osborne County"
    ,

    "Ottawa County"
    ,

    "Owen County"
    ,

    "Owsley County"
    ,

    "Pawnee County"
    ,

    "Pendleton County"
    ,

    "Perry County"
    ,

    "Phillips County"
    ,

    "Pike County"
    ,

    "Pottawatomie County"
    ,

    "Powell County"
    ,

    "Pratt County"
    ,

    "Pulaski County"
    ,

    "Rawlins County"
    ,

    "Reno County"
    ,

    "Republic County"
    ,

    "Rice County"
    ,

    "Riley County"
    ,

    "Robertson County"
    ,

    "Rockcastle County"
    ,

    "Rooks County"
    ,

    "Rowan County"
    ,

    "Rush County"
    ,

    "Russell County"
    ,

    "Russell County"
    ,

    "Saline County"
    ,

    "Scott County"
    ,

    "Scott County"
    ,

    "Sedgwick County"
    ,

    "Seward County"
    ,

    "Shawnee County"
    ,

    "Shelby County"
    ,

    "Sheridan County"
    ,

    "Sherman County"
    ,

    "Simpson County"
    ,

    "Smith County"
    ,

    "Smyth County"
    ,

    "Spencer County"
    ,

    "St. Louis County"
    ,

    "Stafford County"
    ,

    "Stanton County"
    ,

    "Stevens County"
    ,

    "Sumner County"
    ,

    "Taylor County"
    ,

    "Thomas County"
    ,

    "Todd County"
    ,

    "Trego County"
    ,

    "Trigg County"
    ,

    "Trimble County"
    ,

    "Union County"
    ,

    "Wallace County"
    ,

    "Warren County"
    ,

    "Washington County"
    ,

    "Washington County"
    ,

    "Wayne County"
    ,

    "Webster County"
    ,

    "Whitely County"
    ,

    "Whitley County"
    ,

    "Wichita County"
    ,

    "Wilson County"
    ,

    "Wolfe County"
    ,

    "Woodford County"
    ,

    "Woodson County"
    ,

    "Wyandotte County"

  ],


}