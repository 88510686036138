// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.validatortrue {
  display: flex;
  gap: 5px;
  flex-direction: row;
  align-items: center;
  color: red;
}

.validatorfalse {
  display: flex;
  gap: 5px;
  flex-direction: row;
  align-items: center;
  color: green;
}`, "",{"version":3,"sources":["webpack://./src/app/common/password-validator/password-validator.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EAAc,QAAA;EAAU,mBAAA;EAAoB,mBAAA;EAC5C,UAAA;AAIJ;;AADA;EACI,aAAA;EAAc,QAAA;EAAU,mBAAA;EAAoB,mBAAA;EAC5C,YAAA;AAOJ","sourcesContent":[".validatortrue{\n    display: flex;gap: 5px; flex-direction: row;align-items: center;\n    color: red;\n}\n\n.validatorfalse{\n    display: flex;gap: 5px; flex-direction: row;align-items: center;\n    color: green;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
