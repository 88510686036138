import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import { ReactiveFormsModule,FormsModule,UntypedFormGroup,FormControl,Validators,UntypedFormBuilder} from '@angular/forms';
import { AuthService,StaticTextService , NewOrderService, ConfigService} from '../../services';
import { NumberOnlyDirective, LimitToDirective } from '../../directives';
import { DialogService } from "../../module/dialog/bootstrap-modal.module";
import { SuccessComponent } from '../../dialog/success/success.component';
import { MatInputModule } from '@angular/material/input'
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',  
  styleUrls: ['./info.component.scss'],  
})
export class InfoComponent implements OnInit {
  infoFrm:UntypedFormGroup;
  error: string = '';
  errorType: string = '';
  errorMsg: any = {};
  userData: any = {};
  timer:any;

  constructor(
    private router:Router,
    private ref:ChangeDetectorRef,
    private frmBuilder:UntypedFormBuilder,
    private config:ConfigService,
    public text:StaticTextService,
    private newOrder:NewOrderService,
    private auth: AuthService,
    // private dialogService:DialogService
    private matDialog:MatDialog
  ) { 
    this.infoFrm = frmBuilder.group({
        'User_First_Name': [null,Validators.compose([Validators.required, Validators.maxLength(32)])],
        'User_Last_Name': [null,Validators.compose([Validators.required, Validators.maxLength(32)])],        
    })
    this.infoFrm.valueChanges.subscribe((data)=>{
      this.error = '';
    })
  }

  ngOnInit() {
    this.loadData();
    this.timer=setTimeout(() => this.config.setSideBarNode(0), 0);
  }


  ngOnDestroy(){
    console.log("info cmp", this.timer)
    if(this.timer) clearTimeout(this.timer)
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }
  
  goToAccountInfo(){
    this.router.navigate(['user/'+this.auth.getUserId()+'/profile']);
  }
  
  updateInfo(){
    this.auth.updateUserInfo(this.userData)
    .subscribe((res)=>{
        
        this.auth.getProfile()
          .subscribe((data)=>{
            
            if(data){
              this.auth.setCookieFirstName(data.User_First_Name);  //resetting the userFirstName in cookie
              this.auth.setCookieLastName(data.User_Last_Name);    //resetting the userLastName in cookie              
              this.router.navigate(['user/'+this.auth.getUserId()+'/profile']);        
              this.openDialog()       
            }      
          },(err)=>{
            
          })        
        
    },(error)=>{
      
      this.error=error;
      this.errorMsg = error.msg;
      this.errorType = error.type;
    });
  }

  openDialog(){  
    
    let dialogRef=this.matDialog.open(SuccessComponent,{
      data:{
        title:this.text.get('LBL32') + ' '+ this.text.get('LBL07'),
        message: this.text.get('TXT28')
      }
    })
    
    // let disposable = this.dialogService.addDialog(SuccessComponent,{
    //   title:this.text.get('LBL32') + ' '+ this.text.get('LBL07'),
    //   message: this.text.get('TXT28')
    // })
    // .subscribe((res)=>{
      
    // });
  }

  loadData(){
    this.auth.getProfile()
    .subscribe((data)=>{
      
      if(data){
        this.userData.User_First_Name=data.User_First_Name;
        this.userData.User_Last_Name=data.User_Last_Name;
        if(this.userData.User_First_Name){
          document.getElementById('first_name').parentElement.classList.remove('is-empty');
        }  
        if(this.userData.User_Last_Name){
          document.getElementById('last_name').parentElement.classList.remove('is-empty');
        }     
      }      
    },(err)=>{
      
    })
  }
}
