export interface Billing {
	Billing_City: string;
	Billing_State: string;
	Billing_Street_Address_Line_1: string;
	Billing_Street_Address_Line_2?: string;
	Billing_Zip: string;
}

export interface Pricing {
	Order_Custom_Price?: number;
	Order_Final_Price_Tax: number;
	Order_Final_Price: number;
	Order_Max_Tat?: number;
	Order_Min_Tat?: number;
	Order_Status?: number;
}

export interface Property {
	Order_Book?: number;
	Order_Buyer: string;
	Order_Co_Buyer?: string;
	Order_Co_Seller?: string;
	Order_Instructions?: string;
	Order_Loan?: number;
	Order_Mortgage_Amount?: number;
	Order_Mortgage_Date?: Date;
	Order_Page?: number;
	Order_Parcel?: number;
	Order_Seller?: string;
	Property_Address_1: string;
	Property_Address_2?: string;
	Property_City: string;
	Property_County: string;
	Property_First_Name?: string;
	Property_Latitude?: number;
	Property_Longitude?: number;
	Property_Order_Notes?: string;
	Property_Order_Number: number;
	Property_Products: number[];
	Property_State_Abbr: string;
	Property_ZipCode: number;
	Property_Order_Instructions?: string;
	selectedProduct: number;
	Rush_Order?:boolean;
	Order_Estimated_Time?: string;
	Order_Requested_Date?:string;
	Order_Requested_Time?:string;
	Borrower_Address?: {};
	Co_Borrower_Address?:{};
	Borrower_SSN?:string;
	Co_Borrower_SSN?:string;
	Loan_ID?:string;
}

export interface StripeCustomer {
	address: {
		city: string;
		line1: string;
		line2: string;
		postal_code: string;
		state: string;
	};
}

export type DataResets =
	| 'docs'
	| 'billingData'
	| 'pricingData'
	| 'propertyData'
	| 'quoteData'
	| 'newStepData'
	| 'documents'
	| 'searchCriteria'
	| 'searchMode'
	| 'searchType'
	| 'stripeCard'
	| 'stripeCardId'
	| 'stripeCustomerId'
	| 'stripeToken'
	| 'secondStripeToken';
