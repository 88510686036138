// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.centerTextAlign {
  text-align: center;
}

.btn-success {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.88rem;
  font-weight: 400;
  text-transform: uppercase;
  color: white;
  background-color: #76d0f5;
  border-color: #76d0f5;
  border-radius: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/request-quote/request-quote2/request-quote2.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AAEA;EACI,uDAAA;EACA,kBAAA;EACA,gBAAA;EACA,yBAAA;EACA,YAAA;EACA,yBAAA;EACA,qBAAA;EACA,mBAAA;AACJ","sourcesContent":[".centerTextAlign{\n    text-align: center;\n}\n\n.btn-success {\n    font-family: \"Roboto\", \"Helvetica\", \"Arial\", sans-serif;\n    font-size: 0.88rem;\n    font-weight: 400;\n    text-transform: uppercase;\n    color: white;\n    background-color: #76d0f5;\n    border-color: #76d0f5;\n    border-radius: 15px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
