import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { DialogComponent, DialogService } from "../../module/dialog/bootstrap-modal.module";
import { StaticTextService } from '../../services';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
export interface ConfirmModel {
  title: string;
  message: string;
}
@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
  export class SuccessComponent{
  title: any;
  message: any;

  constructor(public text: StaticTextService, public dialogRef: MatDialogRef<SuccessComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmModel,) {

    this.title=data.title;
    this.message=data.message;
  }
  confirm() {
    this.dialogRef.close();
  }

}
