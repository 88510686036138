import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { DialogComponent, DialogService } from "../../module/dialog/bootstrap-modal.module";
import { StaticTextService } from '../../services';
import { Router } from '@angular/router';
import { ConfigService } from '../../services/config/config.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-cancel-dialog',
  templateUrl: './cancel-dialog.component.html',
  styleUrls: ['./cancel-dialog.component.scss']
})
export class CancelDialogComponent{
  title: any;
  message: any;
  buttonValue: any;
  constructor(public text: StaticTextService, private router: Router, private config: ConfigService,
    public dialogRef: MatDialogRef<CancelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) {
    this.title=data.title;
    this.message=data.message;
    this.buttonValue=data.buttonValue
  }


  confirm() {
    this.dialogRef.close({result:true});
  }

  Close() {
    this.dialogRef.close({result:false})
  }

  goToCancellationPolicy() {
    return 'https://pippintitle.com/cancellationpolicy';
  }
  

}
