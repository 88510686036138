
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router} from '@angular/router';
import { AuthService } from '../authentication/auth.service'
import { ConfigService } from '../config/config.service';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import {CONSTANTS} from '../../app.constants';
import * as _  from 'underscore';

@Injectable()
export class PricingService {
direct: HttpClient
  constructor(
    private router:Router,
    private auth:AuthService,
    private config: ConfigService,
    private http:HttpClient,
    private handler: HttpBackend
  ) { 
    this.direct = new HttpClient(this.handler);
  }

  getCustomPricings(pageNum,sortBy,orderBy,searchStr,filterBy){
    var data={
      filterBy:filterBy
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let id = this.auth.getUserOrgId() ?  this.auth.getUserOrgId() : this.auth.getUserId(); 
    let url= this.config.getBasePath() + '/orders/user/'+ id +'/customprices';
    url = url + '/page/' + pageNum + '/size/' + this.config.getNumRecordsPerPage();
    if(sortBy != ''){
      url = url + '/sort/' + sortBy + '/order/' + orderBy;
    }
    if(searchStr){
      url = url + '/search/' + searchStr;
    }
    return this.http.post(url,data,httpOptions).pipe(
      map((response:any) => {
        return response;
      }),
      catchError((error:any) => observableThrowError(error.error)),);
  }

  getPricingStatus(){
    return _.invert(CONSTANTS.customCostStatus);
  }

  getClientRegState() {
    let url = this.config.getBasePath() + '/pricings/client/states/getall';
    return this.http.get(url).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAvailableStateList() {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/pricings/client/states/available/all';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAvailableCountyList(state) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/pricings/client/state/' + state + '/counties/available/all';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getStateList() {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/pricings/client/states/all';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getCountyList(state) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/pricings/client/state/' + state + '/counties/all';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getCountyDirectList(state) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/pricings/client/state/' + state + '/counties/all';
    return this.direct.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getIndpendentCities(){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/pricings/state/all/independent/city';
    return this.direct.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

}
