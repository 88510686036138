// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  border: 1px solid;
  padding: 30px 30px;
  border-radius: 10px;
}

.fromtop {
  margin-top: 3px;
}`, "",{"version":3,"sources":["webpack://./src/app/orders/invoice/invoice.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,kBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,eAAA;AACJ","sourcesContent":[".wrapper{\n    border: 1px solid;\n    padding: 30px 30px;\n    border-radius: 10px;\n}\n\n.fromtop{\n    margin-top: 3px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
