import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { DialogComponent, DialogService } from "../../module/dialog/bootstrap-modal.module";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-nps-success',
  templateUrl: './nps-success.component.html',
  styleUrls: ['./nps-success.component.scss']
})
export class NpsSuccessComponent{
  title: any;
  message: any;
  timer: any;

  constructor(dialogService: DialogService, public dialogRef: MatDialogRef<NpsSuccessComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.title=this.data.title;
  }

  ngOnInit() {
  }

  confirm() {
    // this.result = true;
    this.dialogRef.close({result:true});
  }

  Close() {
    // this.result = false;
    this.dialogRef.close({result:false});
  }

}
