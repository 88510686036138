import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ReactiveFormsModule, FormsModule, UntypedFormGroup, FormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { AuthService, StaticTextService, NewOrderService, ConfigService } from '../../services';
import { DialogService } from "../../module/dialog/bootstrap-modal.module";
import { SuccessComponent } from '../../dialog/success/success.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {

  cpnyFrm: UntypedFormGroup;
  error: string = '';
  errorType: string = '';
  errorMsg: any = {};
  cpnyFrmModel: any = {};
  userData: any = {};
  timer:any;
  constructor(
    private router: Router,
    private frmBuilder: UntypedFormBuilder,
    // private text: StaticTextService,
    public text: StaticTextService,
    private newOrder: NewOrderService,
    private auth: AuthService,
    private ref: ChangeDetectorRef,
    // private dialogService: DialogService,
    private matDialog:MatDialog,
    private config: ConfigService
  ) {
    this.cpnyFrm = frmBuilder.group({
      'Company_Name': [null, Validators.compose([Validators.required, Validators.maxLength(50)])]
    })
    this.cpnyFrm.valueChanges.subscribe((data) => {
      this.error = '';
    })
  }

  ngOnInit() {
    this.auth.getHubSpotTokenDetails();
		this.auth.loadHubSpotChatToken();
    this.loadData();
    this.timer=setTimeout(() => this.config.setSideBarNode(0), 0);
  }

  ngOnDestroy(){
    if(this.timer) clearTimeout(this.timer);
  }

  updateCompanyName() {
    this.userData['User_ID'] = this.auth.getUserId();
    this.userData['Company_Name'] = this.cpnyFrmModel.Company_Name;
    this.auth.updateCompanyName(this.userData)
      .subscribe((res) => {
        this.auth.getProfile()
          .subscribe((data) => {
            
            if (data) {
              this.auth.setCookieCompanyName(data.Company_Name);
              this.router.navigate(['user/' + this.auth.getUserId() + '/profile']);
              this.openDialog()
            }
          }, (err) => {
            
          })
        // this.openDialog()
        // this.router.navigate(['user/' + this.auth.getUserId() + '/profile']);
      }, (error) => {
        
        this.errorMsg = error;
        this.errorType = error.type;
      });
  }




  goToAccountInfo() {
    this.router.navigate(['user/' + this.auth.getUserId() + '/profile']);
  }

  goToNewOrder() {
    this.newOrder.goToNewOrder()
  }

  openDialog() {

    let dialogRef=this.matDialog.open(SuccessComponent,
      {
        data:{
          title: this.text.get('LBL32') + ' ' + this.text.get('LBL33'),
          message: this.text.get('TXT28')
        }
      })

    // let disposable = this.dialogService.addDialog(SuccessComponent, {
    //   title: this.text.get('LBL32') + ' ' + this.text.get('LBL33'),
    //   message: this.text.get('TXT28')
    // })
    //   .subscribe((res) => {
        
    //   });
  }

  loadData() {
    this.auth.getProfile()
      .subscribe((data) => {
        
        if (data) {
          this.cpnyFrmModel['Company_Name'] = data.Company_Name;
          if (this.cpnyFrmModel['Company_Name']) {
            document.getElementById('Company_Name').parentElement.classList.remove('is-empty');
          }
        }
      }, (err) => {
        
      })
  }

}
