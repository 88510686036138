import { Component, Inject } from '@angular/core';
import { DialogComponent, DialogService } from "../../module/dialog/bootstrap-modal.module";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent {
  eulaData : any;
  eulaText;
  isEULATextAvl: boolean = false;
  constructor(dialogService: DialogService,public dialogRef: MatDialogRef<TermsConditionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) {
     this.eulaData=this.data.eulaData;
  }

  ngOnInit() { }

  Close() {
    // this.result = false;
    this.dialogRef.close({result:false})
  }

}
