import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {InvoiceServiceV2} from "../invoice.service"

@Component({
  selector: 'app-invoice-due-card',
  templateUrl: './invoice-due-card.component.html',
  styleUrl: './invoice-due-card.component.scss'
})
export class InvoiceDueCardComponent {
  @ViewChild('detailsTemplate') detailsTemplate: TemplateRef<any>;

  @Input() title:string = ''
  @Input() id:string = ''
  @Input() subtitle:string =''
  @Input() backgroundColor:string=''

  @Input() invoices : Array<{balance:number}> = []
  @Input() onClickDetails: (flag: string) => void;

  isPayingInvoice:boolean = false;


  constructor(public dialog: MatDialog, private invoiceServiceV2: InvoiceServiceV2,) {}

  get totalAmount(): number {
    const reduced =  this.invoices.reduce((sum, invoice) => sum + (invoice.balance), 0);
    return parseFloat(reduced.toFixed(2))
  }

  openDetails(): void {
    this.dialog.open(this.detailsTemplate, {
      width: '60vw',
      height: '40vh',
    });
  }

  payInvoices():void {
    this.isPayingInvoice = true
    this.invoiceServiceV2.paySelectedInvoices(this.invoices).subscribe(({ url }) => {
			this.isPayingInvoice = false;
			window.location.href = url;
		});

  }
}

 
