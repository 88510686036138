// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table.center {
  margin-left: auto;
  margin-right: auto;
}

tbody tr:hover {
  background-color: white !important;
  cursor: auto;
  color: black;
}

thead tr {
  background-color: #111329 !important;
}

table,
td {
  border: 1px solid black;
  text-align: center;
}

th {
  text-align: center;
}

.whiteRightBorder {
  border-top-color: black;
  border-top-style: solid;
  border-top-width: 1px;
  border-right-color: white;
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-color: black;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-color: black;
  border-left-style: solid;
  border-left-width: 1px;
}

.whiteLeftBorder {
  border-top-color: black;
  border-top-style: solid;
  border-top-width: 1px;
  border-right-color: black;
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-color: black;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-color: white;
  border-left-style: solid;
  border-left-width: 1px;
}`, "",{"version":3,"sources":["webpack://./src/app/about/cancellation-policy/cancellation-policy.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,kBAAA;AACF;;AAEA;EACE,kCAAA;EACA,YAAA;EACA,YAAA;AACF;;AAEA;EACE,oCAAA;AACF;;AACA;;EAEE,uBAAA;EACA,kBAAA;AAEF;;AAAA;EACE,kBAAA;AAGF;;AADA;EAEE,uBAAA;EACA,uBAAA;EACA,qBAAA;EACA,yBAAA;EACA,yBAAA;EACA,uBAAA;EACA,0BAAA;EACA,0BAAA;EACA,wBAAA;EACA,wBAAA;EACA,wBAAA;EACA,sBAAA;AAGF;;AAAA;EAEE,uBAAA;EACA,uBAAA;EACA,qBAAA;EACA,yBAAA;EACA,yBAAA;EACA,uBAAA;EACA,0BAAA;EACA,0BAAA;EACA,wBAAA;EACA,wBAAA;EACA,wBAAA;EACA,sBAAA;AAEF","sourcesContent":["table.center {\n  margin-left: auto;\n  margin-right: auto;\n}\n\ntbody tr:hover {\n  background-color: white !important;\n  cursor: auto;\n  color: black;\n}\n\nthead tr {\n  background-color: #111329 !important;\n}\ntable,\ntd {\n  border: 1px solid black;\n  text-align: center;\n}\nth {\n  text-align: center;\n}\n.whiteRightBorder {\n  // border: 1px solid black;\n  border-top-color: black;\n  border-top-style: solid;\n  border-top-width: 1px;\n  border-right-color: white;\n  border-right-style: solid;\n  border-right-width: 1px;\n  border-bottom-color: black;\n  border-bottom-style: solid;\n  border-bottom-width: 1px;\n  border-left-color: black;\n  border-left-style: solid;\n  border-left-width: 1px;\n}\n\n.whiteLeftBorder {\n  // border: 1px solid black;\n  border-top-color: black;\n  border-top-style: solid;\n  border-top-width: 1px;\n  border-right-color: black;\n  border-right-style: solid;\n  border-right-width: 1px;\n  border-bottom-color: black;\n  border-bottom-style: solid;\n  border-bottom-width: 1px;\n  border-left-color: white;\n  border-left-style: solid;\n  border-left-width: 1px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
