import { Directive, OnInit, ElementRef, AfterViewInit, DoCheck, Renderer2 } from '@angular/core';

@Directive({
  selector: '[customAutofocus]'
})

export class CustomAutofocusDirective implements AfterViewInit, DoCheck {
  private lastVisible: boolean = false;
  private initialised: boolean = false;
    timer: any;
  constructor(private el: ElementRef) {
  }

  ngAfterViewInit() {     
      this.initialised = true;
      this.ngDoCheck();
  }

  ngDoCheck() {
      if (!this.initialised) {
          return;
      }
      const visible = !!this.el.nativeElement.offsetParent;
      if (visible && !this.lastVisible) {
          this.timer=setTimeout(() => {
              this.el.nativeElement.focus();
          }, 1);
      }
      this.lastVisible = visible;
  }

  ngOnDestroy(){
      if(this.timer)clearTimeout(this.timer);
  }
}