// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a {
  color: #007bff;
  background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/nps-success/nps-success.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,6BAAA;AACF","sourcesContent":["a {\n  color: #007bff;\n  background-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
