// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  /* border: 1px solid red; */
  text-align: left;
  outline: none;
  font-size: 20px;
  transition: 0.4s;
  margin-bottom: 20px;
}

div.accordion1 {
  background-color: #eee;
  color: #444;
  cursor: auto;
  padding: 18px;
  width: 100%;
  /* border: 1px solid red; */
  text-align: left;
  outline: none;
  font-size: 20px;
  transition: 0.4s;
  margin-bottom: 20px;
}

div.accordion.active, div.accordion:hover {
  background-color: #ddd;
}`, "",{"version":3,"sources":["webpack://./src/app/profile/account-information/account-information.component.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,WAAA;EACA,eAAA;EACA,aAAA;EACA,WAAA;EACA,2BAAA;EACA,gBAAA;EACA,aAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;AACJ;;AACA;EACI,sBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,WAAA;EACA,2BAAA;EACA,gBAAA;EACA,aAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;AAEJ;;AACA;EACI,sBAAA;AAEJ","sourcesContent":["div.accordion {\n    background-color: #eee;\n    color: #444;\n    cursor: pointer;\n    padding: 18px;\n    width: 100%;\n    /* border: 1px solid red; */\n    text-align: left;\n    outline: none;\n    font-size: 20px;\n    transition: 0.4s;\n    margin-bottom:20px;\n}\ndiv.accordion1 {\n    background-color: #eee;\n    color: #444;\n    cursor: auto;\n    padding: 18px;\n    width: 100%;\n    /* border: 1px solid red; */\n    text-align: left;\n    outline: none;\n    font-size: 20px;\n    transition: 0.4s;\n    margin-bottom:20px;\n}\n\ndiv.accordion.active, div.accordion:hover {\n    background-color: #ddd; \n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
