import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import { ReactiveFormsModule,FormsModule,UntypedFormGroup,FormControl,Validators,UntypedFormBuilder} from '@angular/forms';
import { AuthService,StaticTextService , NewOrderService, ConfigService} from '../../services';
import { PhonenumberPipe } from '../../pipes';
import { NumberOnlyDirective, LimitToDirective } from '../../directives';
import { DialogService } from "../../module/dialog/bootstrap-modal.module";
import { SuccessComponent } from '../../dialog/success/success.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  providers:[PhonenumberPipe],
  styleUrls: ['./phone.component.scss']
})
export class PhoneComponent implements OnInit {
  infoFrm:UntypedFormGroup;
  error: string = '';
  errorType: string = '';
  errorMsg: any = {};
  userData: any = {};
  timer:any;
  constructor(
    private router:Router,
    private ref:ChangeDetectorRef,
    private frmBuilder:UntypedFormBuilder,
    private config:ConfigService,
    public text:StaticTextService,
    private newOrder:NewOrderService,
    private auth: AuthService,
    // private dialogService:DialogService
    private matDialog:MatDialog
  ) {
    this.infoFrm = frmBuilder.group({       
        'Contact_Num':[null, Validators.compose([Validators.required, Validators.maxLength(13)])],
        'User_Fax':[null,Validators.compose([Validators.maxLength(20)])]
    })
    this.infoFrm.valueChanges.subscribe((data)=>{
      this.error = '';
    })
   }

  ngOnInit() {
    this.auth.getHubSpotTokenDetails();
		this.auth.loadHubSpotChatToken();
    this.loadData();
    this.timer=setTimeout(() => this.config.setSideBarNode(0), 0);
  }

  ngOnDestroy(){
    console.log("phone cmp", this.timer)
    if(this.timer)clearTimeout(this.timer)
  }
  ngAfterContentChecked() {
    this.ref.detectChanges();
  }
  
  goToAccountInfo(){
    this.router.navigate(['user/'+this.auth.getUserId()+'/profile']);
  }
  
  updateInfo(){
    this.auth.updatePhoneFax(this.userData)
    .subscribe((res)=>{
        this.auth.getProfile()
          .subscribe((data)=>{
            if(data){               
              this.router.navigate(['user/'+this.auth.getUserId()+'/profile']);        
              this.openDialog()       
            }      
          },(err)=>{
            
          })        
        
    },(error)=>{
      
      this.error=error;
      this.errorMsg = error.msg;
      this.errorType = error.type;
    });
  }

  openDialog(){   

    let dialogRef=this.matDialog.open(SuccessComponent,{
      data:{
        title:this.text.get('LBL32') + ' '+ this.text.get('LBL07'),
        message: this.text.get('TXT28')
      }
    })

    
    // let disposable = this.dialogService.addDialog(SuccessComponent,{
    //   title:this.text.get('LBL32') + ' '+ this.text.get('LBL07'),
    //   message: this.text.get('TXT28')
    // })
    // .subscribe((res)=>{
      
    // });
  }

  loadData(){
    this.auth.getProfile()
    .subscribe((data)=>{
      
      if(data){       
        this.userData.Contact_Num=data.Contact_Num;
        if(this.userData['Contact_Num']){
          document.getElementById('Contact_Num').parentElement.classList.remove('is-empty');
        }
        this.userData.User_Fax=data.User_Fax_Contact_Number;
        if(this.userData['User_Fax']){
          document.getElementById('fax').parentElement.classList.remove('is-empty');
        }
      }      
    },(err)=>{
      
    })
  }

}
