// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact {
  font-weight: 700;
}

.font {
  font-size: x-large;
  text-shadow: 0px 0px 2px rgb(170, 170, 170);
}`, "",{"version":3,"sources":["webpack://./src/app/about/contact/contact.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,2CAAA;AACJ","sourcesContent":[".contact {\n    font-weight: 700;\n}\n\n.font{\n    font-size: x-large;\n    text-shadow: 0px 0px 2px rgb(170, 170, 170);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
