import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { AuthService, StaticTextService,NewOrderService, ConfigService, AboutService } from '../../services';
import { DialogService } from "../../module/dialog/bootstrap-modal.module";
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { CONSTANTS } from '../../app.constants'
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-account-information',
  templateUrl: './account-information.component.html',
  styleUrls: ['./account-information.component.scss']
})
export class AccountInformationComponent implements OnInit {
  isMultiCompany = false
  profileModel: any = {};
  completeAddress:string='';
  timer:any;
  constructor(
    private router: Router,
    public text:StaticTextService,
    public auth:AuthService,
    private config:ConfigService,
    private newOrder:NewOrderService,
    private matDialog:MatDialog,
    private aboutService: AboutService,
    private titleService: Title,
  ) { }

  ngOnInit() {
    this.titleService.setTitle(`Pippin Title™ - Client - User - Account`)
    this.auth.getHubSpotTokenDetails();
		this.auth.loadHubSpotChatToken();
    this.auth.getProfile()
    .subscribe((res)=>{
      this.profileModel = res;
      this.buildAddrString(res);
      this.auth.isMultiCompanyUser$.subscribe((state) => {
        this.isMultiCompany = state
        if(this.isMultiCompany) this.getOrgDetailsForMultiUser();
      });
    },(error)=>{
      
      this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      this.auth.logout();
      this.router.navigate(['/login']);
    });  
    this.timer=setTimeout(() => this.config.setSideBarNode(0), 0);   
  }

  emailAddress(){
    this.router.navigate(['user/'+this.auth.getUserId()+'/profile/email']);
  }
  goToChangePassword(){
		this.router.navigate(['user/change-password/' + this.auth.getUserId()]);
	}
  firstName(){
    this.router.navigate(['user/'+this.auth.getUserId()+'/profile/firstname']);
  }
  lastName(){
    this.router.navigate(['user/'+this.auth.getUserId()+'/profile/lastname']);
  }
  address(){
    this.router.navigate(['user/'+this.auth.getUserId()+'/profile/address']);
  }
  phone(){
    this.router.navigate(['user/'+this.auth.getUserId()+'/profile/phone']);
  }
  fax(){
    this.router.navigate(['user/'+this.auth.getUserId()+'/profile/fax']);
  }
  comapnyName(){
    this.router.navigate(['user/'+this.auth.getUserId()+'/profile/company']);
  }
  goToCurrentOrder(){
    this.router.navigate(['orders/current']);
  }

  goToNewOrder(){
    this.newOrder.goToNewOrder()
  }

  buildAddrString(model){
    this.completeAddress = '';
    if(model.Address_1) this.completeAddress+=model.Address_1
    if(model.Address_2){
      if(this.completeAddress) this.completeAddress+=' '
      this.completeAddress+=model.Address_2;
    }
    if(model.Address_City){
      if(this.completeAddress) this.completeAddress+=', '
      this.completeAddress+=model.Address_City
    }
    if(model.Address_State_Abbr){
      if(this.completeAddress) this.completeAddress+=', '
      this.completeAddress+=model.Address_State_Abbr
    }
    if(model.Address_ZipCode){
      if(this.completeAddress) this.completeAddress+=' '
      this.completeAddress+=model.Address_ZipCode
    }
  }

  openErrorPopup(msg) {
    let dialogRef=this.matDialog.open(ErrorDialogComponent,{
      data:{
        message: msg
      }
    });
    
    // let disposable = this.dialogService.addDialog(ErrorDialogComponent, {
    //   message: msg
    // }).subscribe((res) => { });
  }

  ngOnDestroy(){
    if(this.timer) clearTimeout(this.timer);
  }

  getOrgDetailsForMultiUser() {
      this.aboutService.getOrgDetailsByUserNOrgId(this.auth.getUserId(), this.auth.getUserOrgId())
      .subscribe((resp)=>{
        if(resp && resp.Organization_Name) this.profileModel.Organization_Name = resp.Organization_Name;
      },(err)=>{      
        console.log("Error: ", err)
      })
  }

}
