// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.example-button-row {
  display: table-cell;
}

.textSize {
  font-size: 18px;
}

.crossSize {
  font-size: 44px;
  margin-left: 20px;
  cursor: pointer;
  color: #7d7b7b !important;
}

.matclose {
  font-size: 47px;
  margin-left: 15px;
  margin-right: 15px;
  cursor: pointer;
  color: #7d7b7b !important;
}

.material-icons {
  font-size: 2.5rem !important;
}

.inlineDisplay {
  display: flex;
}

section button:hover {
  background-color: #76d0f5 !important;
}

::ng-deep .mat-mdc-snack-bar-container {
  max-width: none !important;
  color: black !important;
  background-color: #ffffff !important;
}

::ng-deep .mat-mdc-raised-button {
  outline: none !important;
}

button {
  border: 1px solid black;
  border-radius: 0px;
  margin: 1.5px;
}`, "",{"version":3,"sources":["webpack://./src/app/common/user-nps/user-nps.component.scss"],"names":[],"mappings":"AAEA;EACE,mBAAA;AADF;;AAIA;EACE,eAAA;AADF;;AAGA;EACE,eAAA;EACA,iBAAA;EACA,eAAA;EACA,yBAAA;AAAF;;AAGA;EACE,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;EACA,yBAAA;AAAF;;AAGA;EACE,4BAAA;AAAF;;AAGA;EACE,aAAA;AAAF;;AAGA;EACE,oCAAA;AAAF;;AAGA;EACE,0BAAA;EACA,uBAAA;EACA,oCAAA;AAAF;;AAGA;EACE,wBAAA;AAAF;;AAGA;EACE,uBAAA;EACA,kBAAA;EACA,aAAA;AAAF","sourcesContent":["@import \"../../../scss/variables\";\n\n.example-button-row {\n  display: table-cell;\n}\n\n.textSize {\n  font-size: 18px;\n}\n.crossSize {\n  font-size: 44px;\n  margin-left: 20px;\n  cursor: pointer;\n  color: #7d7b7b !important;\n}\n\n.matclose {\n  font-size: 47px;\n  margin-left: 15px;\n  margin-right: 15px;\n  cursor: pointer;\n  color: #7d7b7b !important;\n}\n\n.material-icons {\n  font-size: 2.5rem !important;\n}\n\n.inlineDisplay {\n  display: flex;\n}\n\nsection button:hover {\n  background-color: $accept-color !important;\n}\n\n::ng-deep .mat-mdc-snack-bar-container {\n  max-width: none !important;\n  color: black !important;\n  background-color: #ffffff !important;\n}\n\n::ng-deep .mat-mdc-raised-button {\n  outline: none !important;\n}\n\nbutton {\n  border: 1px solid black;\n  border-radius: 0px;\n  margin: 1.5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
