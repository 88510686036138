
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from '../config/config.service';
import { AuthService } from '../authentication/auth.service';

@Injectable()
export class StaticTextService {

   staticText: any = {};

  constructor(
    private http: HttpClient,
    private cookies: CookieService,
    private config: ConfigService,
    private auth:AuthService,
  ) { }

  setApplicationText(){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/labels';
    return this.http.get(url,httpOptions).pipe(
    map(
      (response:any)=>{
        this.staticText = response;
      },(err)=>{
        
    }),
    catchError((err)=>{throw err.error}),)
  }

  get(code){
    if(this.staticText[code]) {
      let tempTxt = this.staticText[code][0].replace(/\[|\]/g, "");
      let txtWithComma = tempTxt.replace(/comma/g, ",");
      // let tempTxt = this.staticText[code][0];  
      // let txtWithComma = tempTxt.replace(new RegExp(/[comma]/), ",");  
      
      //
      return txtWithComma;
    } else return '';
  }
  
}
