// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*.modal-body{
    height:300px;
    overflow-y: scroll
}

.modal-content {
    border: none;
    height: 40%;
    top: -1;
    bottom: 200px;
    width: 150%;
    text-align: justify;
}*/
.modal-content {
  bottom: 200px;
  width: 150%;
}

.modal-body {
  position: relative;
  overflow-y: auto;
  /* max-height: 400px; */
  height: 250px;
  padding: 15px;
  text-align: justify;
  font-size: 16px !important;
}

.modal-footer {
  padding-left: 15px;
  padding-right: 15px;
}

.modal {
  font-size: 16px !important;
}

.glyphicon-remove {
  top: 20px;
  right: 15px;
  font-size: 18px;
}

u {
  cursor: pointer;
}

.msg-text {
  white-space: pre-wrap;
}

hr {
  margin: 0px;
}

.timestamp {
  margin-left: -3em;
}

.timestamp1 {
  margin-left: -2.5em;
}

.msg-card {
  margin-top: -3.5em;
  margin-bottom: -5.5em;
}

.msg-card1 {
  margin-top: -4.8em !important;
  margin-bottom: -4.5em !important;
}

.btn-width {
  width: 148px;
}

b {
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/displaymessage/displaymessage.component.scss"],"names":[],"mappings":"AAAA;;;;;;;;;;;;EAAA;AAcA;EACI,aAAA;EACA,WAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,gBAAA;EACA,uBAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,0BAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,mBAAA;AAAJ;;AAGA;EACI,0BAAA;AAAJ;;AAGA;EACI,SAAA;EACA,WAAA;EACA,eAAA;AAAJ;;AAGA;EACI,eAAA;AAAJ;;AAGA;EACI,qBAAA;AAAJ;;AAGA;EACI,WAAA;AAAJ;;AAGA;EACI,iBAAA;AAAJ;;AAGA;EACI,mBAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,qBAAA;AAAJ;;AAGA;EACI,6BAAA;EACA,gCAAA;AAAJ;;AAGA;EACI,YAAA;AAAJ;;AAGA;EACI,YAAA;AAAJ","sourcesContent":["/*.modal-body{\n    height:300px;\n    overflow-y: scroll\n}\n\n.modal-content {\n    border: none;\n    height: 40%;\n    top: -1;\n    bottom: 200px;\n    width: 150%;\n    text-align: justify;\n}*/\n\n.modal-content {\n    bottom: 200px;\n    width: 150%;\n}\n\n.modal-body {\n    position: relative;\n    overflow-y: auto;\n    /* max-height: 400px; */\n    height: 250px;\n    padding: 15px;\n    text-align: justify;\n    font-size: 16px !important;    \n}\n\n.modal-footer{\n    padding-left: 15px;\n    padding-right: 15px;\n}\n\n.modal {\n    font-size: 16px !important;\n}\n\n.glyphicon-remove{\n    top: 20px;\n    right: 15px;\n    font-size: 18px;\n}\n\nu{\n    cursor: pointer;\n}\n\n.msg-text{\n    white-space: pre-wrap;\n}\n\nhr{\n    margin: 0px;\n}\n\n.timestamp{\n    margin-left: -3.0em;\n}\n\n.timestamp1{\n    margin-left: -2.50em;\n}\n\n.msg-card{\n    margin-top: -3.5em;\n    margin-bottom: -5.5em;\n}\n\n.msg-card1 {\n    margin-top: -4.8em !important;\n    margin-bottom: -4.5em  !important;\n}\n\n.btn-width{\n    width:148px\n}\n\nb{\n    color:black;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
