import { Component, OnInit,ChangeDetectorRef,ViewChild } from '@angular/core';
import { UntypedFormGroup,FormsModule,UntypedFormBuilder,FormControl,Validators,ReactiveFormsModule} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, EmailValidator, StaticTextService, ConfigService } from '../../services';
import { DialogService } from "../../module/dialog/bootstrap-modal.module";
import { SuccessComponent } from '../../dialog/success/success.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-forget',
  templateUrl: './forget.component.html',
  styleUrls: ['./forget.component.scss']
})
export class ForgetComponent implements OnInit {

  forgetFrm:UntypedFormGroup;
  forgetModel:any = {};
  error: string = '';
  errorMsg: any = {};
  @ViewChild('myfocus', { static: true }) myfocus:any;
  timer: any ;
  constructor(
    public text: StaticTextService,
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    private router: Router,
    // private dialogService : DialogService,
    private matDialog:MatDialog,
    private config:ConfigService
  ) { 
    this.forgetFrm = fb.group({
      'Email_Address': [null, Validators.compose([Validators.required, Validators.maxLength(255)])]
    })
     this.forgetFrm.valueChanges.subscribe(data => this.error = '');
  }

  ngOnInit() {
    this.auth.loadHubSpotChatToken();
    if (this.auth.getEmailIdForForgetPwd() != undefined) {
      this.forgetModel.Email_Address = this.auth.getEmailIdForForgetPwd();
      
    }
   this.timer=setTimeout(() => this.config.setSideBarNode(0), 0);
    this.myfocus.nativeElement.focus(); 
  }

  ngOnDestroy(){
    if(this.timer)clearTimeout(this.timer)
  }

  forget(){
    
    this.auth.forget(this.forgetModel)
    .subscribe(()=>{
      let dialogRef=this.matDialog.open(SuccessComponent,{
        data:{
          title:this.text.get('TXT26'), 
          message: this.text.get('TXT32')
        }
      })

      dialogRef.afterClosed().subscribe((res)=>{
        this.router.navigate(['login']);
      })

      // let disposable = this.dialogService.addDialog(SuccessComponent, {
      //         title:this.text.get('TXT26'), 
      //         message: this.text.get('TXT32')
      //       }).subscribe((res)=>{
              
      //         this.router.navigate(['login']);
      //       });
    },
    (error)=>{
       
      this.errorMsg = error;
    });
  }
   changeModelVal(modelVal){
    this.errorMsg = {};
  }

  goToLogin(){
    this.router.navigate(['login']);
  }
  
}
