import { Component, OnInit, ViewChild, ElementRef, Inject  } from '@angular/core';
import { DialogComponent, DialogService } from "../../module/dialog/bootstrap-modal.module";
import { AuthService, StaticTextService } from '../../services';
import { CONSTANTS } from '../../app.constants'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent {

  title: any;
  message: any;
  constructor(dialogService: DialogService, public text: StaticTextService,  public auth: AuthService,
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
     this.title=this.data.title;
     this.message=this.data.message;
  }

  Close() {
    // this.result = false;
    // this.close()
    this.dialogRef.close({result:false});
  }

}
