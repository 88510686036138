import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DialogComponent, DialogService } from "../../module/dialog/bootstrap-modal.module";
import { AuthService, StaticTextService } from '../../services';
import { AutogrowDirective } from '../../directives';
import { CONSTANTS } from '../../app.constants';
import { Router } from '@angular/router';
import * as momentTimeZone from 'moment-timezone';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ErrorDialogComponent } from '@dialog/error-dialog/error-dialog.component';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent{

  @ViewChild('replyBtn') focusedElement: any;
  @ViewChild('cancelbtn', { static: true }) focusedElement1: any;


  title: any;
  message: any;
  messageText = '';
  t1 = '';
  t2 = '';
  t3 = '';
  
  constants = CONSTANTS;
  isReply: boolean = false;
  constructor(public text: StaticTextService, public auth: AuthService,private router: Router,
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.message=this.data.message;
      this.t1=this.data.t1;
      this.t2=this.data.t2;
      this.t3=this.data.t3;
  }

  ngOnInit() {
    
    // if (this['message']) {
    //   this.messageText += this['message'];
    // }  
    if (this.message == undefined) this.isReply = true;
    else this.isReply = false;
  }

  ngAfterViewInit() {
    
    
    // if (this.focusedElement != undefined) {
    //   this.focusedElement.nativeElement.focus();
    // }
    // if (this.focusedElement == undefined) {
    //   if (this.focusedElement1 != undefined) {
    //     this.focusedElement1.nativeElement.focus();
    //   }
    // }

  }


  reply() {
    this.isReply = true;
    
  }

  isReplyClicked() {
    if (this.isReply) return true;
    else return false;
  }

  send() {
    // this.result = this.messageText;
    this.dialogRef.close({result:this.messageText});
  }


  isString() {
    if (typeof this.message == 'string') return true;
    else return false;
  }

  isMessageEmpty(){
    
    return this.messageText.trim().length == 0;

  }

  Close() {
    // this.result = false;
    this.dialogRef.close(null);
  }
  getClick(id){
    if(this.t1 && this.t1.match('Quote ')) this.router.navigate(['/quote/'+ id + '/view' ])
    else this.router.navigate(['/orders/' + id + '/view'])
    this.dialogRef.close();
  }

  checkDaylight(etaDate) {
    if (momentTimeZone.tz(etaDate, 'America/New_York').isDST()) return 'EDT';
    else return 'EST'
  }

}
