import { Component, OnInit } from '@angular/core';
import { CONSTANTS } from '../../app.constants'
import {
  AuthService, StaticTextService, PagerService, StateService, ConfigService, ScrollService, QuoteService, PreloaderService
} from '../../services';
import * as momentTimeZone from 'moment-timezone';
import { Router } from '@angular/router';
import {forkJoin as observableForkJoin,  Observable, Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.scss']
})
export class QuotesComponent implements OnInit {
  displayedColumns: string[] = ['Titles_ID','File_ID','Property_First_Name','Property_Address_1','Property_State_Abbr','Property_County','Order_Status',	'Order_Creation_Date','Quote_ETA',
'Order_Completion_Date'];
  dataSource= new MatTableDataSource<any>([]);

  sortEvent:any=null;
  constants = CONSTANTS
  quotes: any = []
  orderStatus: any = {};
  quotesToDisplay: any;
  totalCount: number;
  pager: any = {};
  searchString: string;
  searchInProgress: boolean = false;
  sortField: string;
  orderBy: string;
  pageNum: number;
  listingType = 'my'
  filterBy: any = [];
  timer:any = [];
  scrollEnable: boolean = true;
  srollDistance=this.config.setScrollDistance();
  srollThrottle=this.config.setScrollThrottle();
  searchQuery = new Subject<string>();
  hasScrollDown:boolean=false;''
  constructor(
    private config: ConfigService,
    private quoteService:QuoteService,
    public text: StaticTextService,
    private scrollService: ScrollService,
    private preloaderService: PreloaderService,
    private router: Router,
    private pagerService: PagerService,
    private stateService: StateService,
    private auth:AuthService,
    private titleService: Title,

  ) {
    this.pager.pages = [];
    if(this.pageNum == undefined)
    this.pageNum = 1;
    this.orderBy = 'DESC';
    this.sortField = 'Order_Creation_Date';
    this.searchString = '';
    this.searchQuery.pipe(
    debounceTime(this.config.getDebounceTime()),
    distinctUntilChanged())
    .subscribe(value => this.searchField(value));
   }

  ngOnInit() {
    this.titleService.setTitle(`Pippin Title™ - Client - Quotes`)
    this.auth.getHubSpotTokenDetails();
		this.auth.loadHubSpotChatToken();
    if (this.stateService.getQuoteRequest()) this.initializeComponentData(this.stateService.getQuoteRequest());
    else {
      this.checkListingType()
      this.getQuoteRequest();
    }
    this.timer.push(setTimeout(() => this.config.setSideBarNode(7), 0));
  }

  getQuoteRequest(){
    if(this.pageNum == 1){
      this.stateService.setQuoteRequest(this);
      this.quoteService.getQuoteReqData(this.pageNum, this.sortField, this.getSearchString(), this.orderBy, this.filterBy)
      .subscribe((data) => {
        this.getQuoteData(data);
        this.dataSource.data=this.quotes;
      })
    }else this.getRetainQuoteData();
  }

  getQuoteData(result){
    this.quotes = result.rows;
    this.totalCount = result.count;
    this.getQuotesRequestData(this.totalCount)
    this.getScrollPosData();
  }

  searchField(search){
    this.scrollService.setScrollPosQuoteReq(0)
    var format = /[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]/;
    search = search.trim();
    this.searchString = search;
    if(!format.test(this.searchString)&& this.searchString==" "){
      this.searchString='';
      this.getQuoteRequest();
    }else this.findQuoteRequest();
  }

  initializeComponentData(data) {
    this.pager = data.pager;
    this.pageNum = data.pageNum;
    this.searchString = data.searchString;
    this.orderBy = data.orderBy;
    this.sortField = data.sortField;
    this.totalCount = data.totalCount;
    this.quotesToDisplay = data.quotesToDisplay;
    this.searchInProgress = data.searchInProgress;
    this.listingType = data.listingType;
    this.checkListingType();
    this.getQuoteRequest();
  }

  findQuoteRequest() {
    this.pageNum = 1;
    this.preloaderService.setSearchSpin();
    this.searchInProgress = true;
    this.getQuoteRequest();
  }

  getSearchString() {
    var format = /[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]/;
    if (format.test(this.searchString)) {
      return '';
    } else return this.searchString;
  }


  setSort(field: string) {
    this.pageNum = 1;
    if (field == this.sortField) {
      if (this.orderBy == 'ASC') this.orderBy = 'DESC';
      else this.orderBy = 'ASC';
    } else {
      this.sortField = field;
      this.orderBy = 'ASC';
    }
    this.getQuoteRequest();
  }

  ngOnDestroy(){
    if(this.timer){
    this.timer.forEach(time => clearTimeout(time));
    }
  }

  setSortDefaultAsDec(field: string) {
    this.pageNum = 1;
    if (field == this.sortField) {
      if (this.orderBy == 'ASC') this.orderBy = 'DESC';
      else this.orderBy = 'ASC';
    } else {
      this.sortField = field;
      this.orderBy = 'DESC';
    }
    this.getQuoteRequest();
  }

  goToQuoteDetails(quoteId){
    var getPos = document.documentElement.scrollTop
    this.scrollService.setScrollPosQuoteReq(getPos);
    this.router.navigate(['/quote/'+ quoteId + '/view'])
  }

  checkDaylight(etaDate) {
    if (momentTimeZone.tz(etaDate, 'America/New_York').isDST()) return 'EDT';
    else return 'EST'
  }

  onScrollDown() {
    this.config.setClearTimeout();
    this.config.setDelayRecords();
    this.onScrollData();
  }

  onScrollData() {
    if (this.pageNum < this.pager.totalPages) {
      this.pageNum++;
      this.quoteService.getQuoteReqData(this.pageNum, this.sortField, this.getSearchString(), this.orderBy, this.filterBy)
        .subscribe((result) => {
          if (result) {
            this.totalCount = result.count;
            this.quotes = this.quotes.concat(result.rows);
            this.pager = this.pagerService.getPager(this.totalCount, this.pageNum);
            this.dataSource.data=this.quotes;
            if(this.sortEvent){
              this.onSortChange(this.sortEvent);
            }
            this.hasScrollDown=false;
          }
        })
    }
  }

  getRetainQuoteData(){
    var ObsCollection = [];
    for(var i=1; i<=this.pageNum; i++){
      ObsCollection.push(this.quoteService.getQuoteReqData(i, this.sortField, this.getSearchString(), this.orderBy, this.filterBy))
    }
    observableForkJoin(ObsCollection)
      .subscribe((retainedQuotes) => {
        if(retainedQuotes)
          this.getRetainQuoteRequest(retainedQuotes)
      })
  }

  getRetainQuoteRequest(retainedQuotes) {
      var totalOrders = [];
      for(var i=0; i<retainedQuotes.length; i++){
        this.stateService.setQuoteRequest(this);
        totalOrders = totalOrders.concat(retainedQuotes[i]['rows']);
      }
      this.quotes = totalOrders;
      this.totalCount = retainedQuotes[0]['count'];
      this.getQuotesRequestData(this.totalCount)
      this.getScrollPosData()
  }

  getQuotesRequestData(result) {
    this.config.setClearTimeout();
    this.setQuoteReqData();
    this.pager = this.pagerService.getPager(result, this.pageNum);
    this.searchInProgress = false;
    this.config.setDelayRecords();
  }

  setQuoteReqData() {
      let start = ((this.pageNum - 1) * this.config.getNumRecordsPerPage());
      this.quotesToDisplay = this.quotes.slice(start, this.totalCount);
      if (this.quotesToDisplay.length > this.config.getNumRecordsPerPage()) this.quotesToDisplay = this.quotesToDisplay.slice(0, this.config.getNumRecordsPerPage());
  }

  getScrollPosData(){
    if(this.scrollService.getScrollPosQuoteReq()){
      var x = this.scrollService.getScrollPosQuoteReq();
      this.timer.push(setTimeout(function() {
        window.scrollTo(0,x);
      }))
    }
  }

  onTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  handleEvent(event){
    this.scrollEnable=event;
  }

  detectTypeChange(event) {
    this.pageNum = 1;
    document.documentElement.scrollTop = 0
    var x = document.documentElement.scrollTop
    this.scrollService.setScrollPosOpendOrd(x);
    this.checkListingType();
    this.getQuoteRequest();
  }

  checkListingType(){
    if (!this.auth.isIndividualUser()) {
      if (this.listingType == 'my') this.filterBy.push({ Customer_ID: this.auth.getUserId() });
      else this.filterBy.pop('Customer_ID');
    }
  }

  isIndividualUser() {
    return this.auth.isIndividualUser();
  }


  onTableScroll(e) {
    // Check if the scroll position is at the 90% of height of the table
     if (!this.hasScrollDown && ((e.target.scrollTop + e.target.clientHeight)/(e.target.scrollHeight))>0.9){
        this.hasScrollDown=true;
        this.onScrollDown()
    }
  }


onSortChange(e){
  this.sortEvent=e;
this.dataSource.data = this.applySortingLogic([...this.dataSource.data], e);
}

applySortingLogic(data: any[], sortEvent: any): any[] {
if (sortEvent.direction === '') {
  return this.quotes;
}
const sortedData = data.sort((a, b) => {
  const isAsc = sortEvent.direction === 'asc';
  const column = sortEvent.active;

  if(!a[column] && !b[column]){
    return;
  }

  if(!a[column] || !b[column]){

    if(!a[column]){
      return isAsc ? "-".localeCompare(b[column]) : b[column].localeCompare("-");
    }else if(!b[column]){
      return isAsc ? a[column].localeCompare("-") : "-".localeCompare(a[column]);
    }
  }

  // console.log(a[column])
  if(typeof(a[column])=="number"){
    const comparisonResult = (a[column] > b[column]) ? 1 : ((a[column] < b[column]) ? -1 : 0);
    return isAsc ? comparisonResult : -comparisonResult;
  }
  

  return isAsc ? a[column].localeCompare(b[column]) : b[column].localeCompare(a[column]);
});

return sortedData;
}

}
