declare var google: any;
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService, ConfigService, PricingService, ProgressBarService, StaticTextService} from '../../services';
import {PhonenumberPipe} from '../../pipes/phonenumber/phonenumber.pipe';
import {CONSTANTS} from '../../app.constants';

@Component({
  selector: 'app-registration',
  providers: [PhonenumberPipe],
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],

})
export class RegistrationComponent implements OnInit {

  registerFrm: UntypedFormGroup;
  error: string = '';
  errorMsg: any = {};
  forgetPwdErrorMsg: any = '';
  errorType: string = '';
  registerModel: any = {};
  latitute: any;
  longitude: any;
  isCompany: boolean = true;
  companylabel: string = '';
  states = [];
  googleCaptchaKey: string = '';
  typeID: number = +this.route.snapshot.params['id'];
  timer: any =[];
  //primaryColor:String='#0F9D58';
  //primaryColor:String='#039ff4'
  constructor(
    public text: StaticTextService,
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private ref: ChangeDetectorRef,
    private progressServ: ProgressBarService,
    private config: ConfigService,
    private pricingService: PricingService
  ) {

    if (this.typeID == CONSTANTS.userType.individual) {
      this.registerFrm = fb.group({
        User_First_Name: [null, Validators.compose([Validators.required, Validators.maxLength(32)])],
        User_Last_Name: [null, Validators.compose([Validators.required, Validators.maxLength(32)])],
        Contact_Num: [null, Validators.compose([Validators.required, Validators.maxLength(13)])],
        //Company_Name: [null, Validators.compose([Validators.required, Validators.maxLength(127)])],
        Company_Name: [null, Validators.compose([Validators.maxLength(127)])],
        locality: [null, Validators.compose([Validators.maxLength(32)])],// address line 1
        Address_2: [null, Validators.compose([Validators.maxLength(60)])],
        zipCode: [null, Validators.compose([Validators.maxLength(11)])],
        state: [null],
        Contact_Type: [null],
        city: [null, Validators.compose([Validators.maxLength(38)])],
        Email_Address: [null, Validators.compose([Validators.required, Validators.maxLength(255)])],
        Confirm_Email_Address: [null, Validators.required],
        // recaptchaReactive: [null, Validators.required]
      })
    }
    else {
      this.registerFrm = fb.group({
        User_First_Name: [null, Validators.compose([Validators.required, Validators.maxLength(127)])],
        User_Last_Name: [null, Validators.compose([Validators.required, Validators.maxLength(127)])],
        Contact_Num: [null, Validators.compose([Validators.required, Validators.maxLength(13)])],
        Company_Name: [null, Validators.compose([Validators.required, Validators.maxLength(127)])],
        locality: [null, Validators.compose([Validators.maxLength(127)])],// address line 1
        Address_2: [null, Validators.compose([Validators.maxLength(60)])],
        zipCode: [null, Validators.compose([Validators.maxLength(11)])],
        state: [null],
        Contact_Type : [null],
        city: [null, Validators.compose([Validators.maxLength(38)])],
        Email_Address: [null, Validators.compose([Validators.required, Validators.maxLength(255)])],
        Confirm_Email_Address: [null, Validators.required],
        // recaptchaReactive: [null, Validators.required]
      })
    }
    this.registerFrm.valueChanges.subscribe((data) => {
      // if (data.User_First_Name) {
      //
      //   this.registerModel.User_First_Name =  data.User_First_Name.trim();
      // }
      this.error = '';
    });

  }

  isRegAsIndividual(){
    if(this.typeID == CONSTANTS.userType.individual) return true;
    else return false;
  }

  ngOnInit() {
    // this.auth.init().subscribe((res) => {});
    this.registerModel.Contact_Type = 'M';
    this.googleCaptchaKey = this.config.getGoogleCaptchaKey();
    this.pricingService.getClientRegState()
    .subscribe((states: any[]) => { this.states = states });
    //  let searchBox: any = document.getElementById('search-box');
    //     let options = {
    //       types: [
    //         // return only geocoding results, rather than business results.
    //         'geocode',
    //       ]
    //     };
    //     var autocomplete = new google.maps.places.Autocomplete(searchBox, options);
    //     //
    //     // Add listener to the place changed event
    //     autocomplete.addListener('place_changed', () => {
    //       this.getPlaceDetails(autocomplete.getPlace());
    //       this.ref.detectChanges();
    //     });

    this.companylabel = '';
    if (this.typeID == 1) this.isCompany = false;
    else {
      this.isCompany = true;
      this.companylabel = ' *';
    }
    this.timer.push(setTimeout(() => this.config.setSideBarNode(0), 0));
    this.CaptchaCallback();
  }

  CaptchaCallback () {
		grecaptcha.render('RecaptchaField1', {'sitekey' : this.config.getGoogleCaptchaKey()});
      }


  getCaptchaValidity(){
    return grecaptcha.getResponse().length != 0 ? true : false;
  }

  ngAfterViewInit() {
    this.auth.loadHubSpotChatToken();
    this.timer.push(setTimeout(() => {
      let searchBox: any = document.getElementById('search-box');
      let options = {
        types: [
          // return only geocoding results, rather than business results.
          'geocode',
        ]
      };
      var autocomplete = new google.maps.places.Autocomplete(searchBox, options);

      // Add listener to the place changed event
      autocomplete.addListener('place_changed', () => {
        this.getPlaceDetails(autocomplete.getPlace());
        this.ref.detectChanges();
      });
    }, 1000));
    console.log("Working in Registration")
  }

  ngOnDestroy(){
    this.timer.forEach((time)=>{
      clearTimeout(time)
    });
  }

  assignValue(event){
    this.registerModel.User_First_Name=event

  }

  Registration() {
    this.errorMsg = {};
    this.forgetPwdErrorMsg = '';
    let userObject: any = {};

    userObject["User_First_Name"] = this.registerModel.User_First_Name,
      userObject["User_Last_Name"] = this.registerModel.User_Last_Name,
      userObject["Contact_Num"] = this.registerModel.Contact_Num,
      userObject["Email_Address"] = this.registerModel.Email_Address,
      userObject["Company_Name"] = this.registerModel.Company_Name,
      userObject["Address_1"] = this.registerModel.locality,
      userObject["Address_2"] = this.registerModel.Address_2,
      userObject["Address_City"] = this.registerModel.city,
      userObject["Address_State_Abbr"] = this.registerModel.state,
      userObject["Address_ZipCode"] = this.registerModel.zipCode
    userObject["User_Type"] = this.typeID;
    this.auth.registerNewUser(userObject)
      .subscribe(
      (response) => {
        if(response.body.approved) {
          this.router.navigate(['login','approved'])
        }else this.router.navigate(['login', 'success']);
      },
      (error) => {
        // this.progressServ.setStage(1);
        // this.progressServ.setProgressState((1/2)*100);
        if (error.code == "PTU16") {
          this.forgetPwdErrorMsg = "Your email address is already in use. If you forgot your password, please reset it using";

        }
        else this.errorMsg = error;
      });
  }


  // get diagnostic() {
  //   return JSON.stringify(this.registerModel);

  // }

  display() {
    //
    //
  }

  // ngAfterContentChecked() {
  //   this.ref.detectChanges();
  // }


  getPlaceDetails(place: any) {
    this.registerModel.locality = '';
    this.registerModel.Address_1 = '';
    this.registerModel.Address_2 = '';
    this.registerModel.city = '';
    this.registerModel.state = '';
    this.registerModel.zipCode = '';
    this.registerModel.Contact_Type = 'M';
    this.latitute = '';
    this.longitude = '';
    document.getElementById('search-box').parentElement.classList.add('is-empty');
    document.getElementById('Address_2').parentElement.classList.add('is-empty');
    document.getElementById('city').parentElement.classList.add('is-empty');
    document.getElementById('state').parentElement.classList.add('is-empty');
    document.getElementById('zipCode').parentElement.classList.add('is-empty');


    if (place.geometry == undefined) {
      //set an error - show the error under google search line
      // a custom validator need to be defined and an error message "Place not found" need to be shown
      return;
    } else {

    }
    this.latitute = place.geometry.location.lat();
    this.longitude = place.geometry.location.lng();

    if (place.address_components.length) {
      for (let i = 0; i < place.address_components.length; i++) {

        switch (place.address_components[i].types[0]) {
          case 'street_number': this.registerModel.locality = place.address_components[i].long_name + ' ';
            document.getElementById('search-box').parentElement.classList.remove('is-empty');
            break;
          case 'route': this.registerModel.locality = this.registerModel.locality + place.address_components[i].long_name;
            document.getElementById('search-box').parentElement.classList.remove('is-empty');
            break;
          case 'locality': {

            //if(this.registerModel.city.length==0)
            this.registerModel.city = place.address_components[i].long_name;
            document.getElementById('city').parentElement.classList.remove('is-empty');
            break;
          }
          case 'administrative_area_level_2': {
            //if (this.registerModel.city.length == 0)
            //this.registerModel.city = place.address_components[i].long_name;
            break;
          }
          case 'administrative_area_level_1': this.registerModel.state = place.address_components[i].short_name;
            document.getElementById('state').parentElement.classList.remove('is-empty');
            break;
          case 'postal_code': this.registerModel.zipCode = place.address_components[i].short_name;
            document.getElementById('zipCode').parentElement.classList.remove('is-empty');
            break;
          default: break;
        }
      }
    } else {
      this.registerModel.locality = '';
    }
  }

  goToBackPage() {
    this.router.navigate(['registration/step1']);
  }

  goToForget(userEmail) {
    if (userEmail != undefined) {
      this.auth.setEmailIdForForgetPwd(userEmail);
    }
    this.router.navigate(['/forget']);
  }
  clearZipcode(){
    this.registerFrm.controls['zipCode'].reset();
  }
}
