// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prv-msg {
  color: black;
  text-align: left;
  font-size: 16px;
}

#message-box textarea {
  text-align: left;
}

.modal-body {
  overflow-y: auto;
  height: 250px;
  padding: 15px;
  text-align: justify;
  font-size: 16px !important;
  background-color: white;
}

.modal-footer {
  padding-left: 15px;
  padding-right: 15px;
  background-color: white;
}

.glyphicon-remove {
  top: 20px;
  right: 15px;
  font-size: 18px;
}

.msg-text {
  white-space: pre-wrap;
}

hr {
  margin-bottom: 0px;
}

.msgPadding {
  margin-top: -10em;
}

.timestamp1 {
  margin-left: -3.95em;
}

.msgFormat1 {
  margin-top: -1.7rem !important;
  margin-left: -1em;
}

.msgAlignment {
  margin-bottom: 1em;
}

.btn-width {
  width: 148px;
}

b {
  color: black;
}

textarea {
  padding: 4px 4px;
}

.modal-content {
  width: 100%;
}

.orderlink {
  text-decoration: underline;
  text-decoration-color: white;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/message/message.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,gBAAA;EACA,eAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,0BAAA;EACA,uBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,mBAAA;EACA,uBAAA;AACJ;;AAEA;EACI,SAAA;EACA,WAAA;EACA,eAAA;AACJ;;AACA;EACI,qBAAA;AAEJ;;AACA;EACI,kBAAA;AAEJ;;AACA;EACI,iBAAA;AAEJ;;AACA;EACI,oBAAA;AAEJ;;AAAA;EACI,8BAAA;EACA,iBAAA;AAGJ;;AADA;EACI,kBAAA;AAIJ;;AADA;EACI,YAAA;AAIJ;;AADA;EACI,YAAA;AAIJ;;AADA;EACI,gBAAA;AAIJ;;AAAA;EACI,WAAA;AAGJ;;AAAA;EACI,0BAAA;EACA,4BAAA;EACA,eAAA;AAGJ","sourcesContent":[".prv-msg{\n    color: black;\n    text-align: left;\n    font-size: 16px;\n}\n\n#message-box textarea{\n    text-align: left;\n}\n\n.modal-body {\n    overflow-y: auto;\n    height: 250px;\n    padding: 15px;\n    text-align: justify;\n    font-size: 16px !important;  \n    background-color: white;  \n}\n\n.modal-footer{\n    padding-left: 15px;\n    padding-right: 15px;\n    background-color: white;\n}\n\n.glyphicon-remove{\n    top: 20px;\n    right: 15px;\n    font-size: 18px;\n}\n.msg-text{\n    white-space: pre-wrap;\n}\n\nhr{\n    margin-bottom: 0px; \n}\n\n.msgPadding{\n    margin-top: -10em; \n  }\n\n.timestamp1{\n    margin-left: -3.95em;\n}\n.msgFormat1{\n    margin-top: -1.7rem !important;\n    margin-left: -1.0em;\n}\n.msgAlignment{\n    margin-bottom: 1em;\n}\n\n.btn-width{\n    width:148px\n}\n\nb{\n    color:black;\n}\n\ntextarea{\n    padding: 4px 4px;\n}\n\n\n.modal-content{\n    width:100%;\n\n}\n.orderlink{\n    text-decoration: underline;\n    text-decoration-color: white;\n    cursor: pointer;    \n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
