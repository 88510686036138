import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie';

@Injectable()
export class ConfigService {
	private basePath: string;

	private access_token: string;

	private numberOfRecords: number = 25;

	private noOfRecords: number = 50;

	private sideBarNode: number = 0;

	private scrollYThreshold = 300;

	private showingRecords: boolean = true;

	private infiniteScrollThrottle = 800;

	private infiniteScrollDistance = 0.1;

	private amountMaxLength = 15;

	private maxFileSize = 1000;

	private debounceTime = 2000;

	private app_id: string;

	timer: any;

	private hubSpotTrackingCode: any;

	private namesearchProductId: number;

	/* ---------------------------------- */

	constructor(private cookies: CookieService) {}

	/* ---------------------------------- */

	public getBasePath = (): string => {
		return environment.basePath;
	};
	public getClientHome = (): string => {
		return environment.clientHome;
	};
	public get baseUrl() {
		return environment.basePath;
	}

	private getEnvData(){
		return this.cookies.getObject('panelData');
	}

	public getS3Region = (): string => {
		return this.getEnvData() ? this.b64_to_utf8(this.getEnvData()['aws_region']): null;
	};
	public get s3Region() {
		return this.getEnvData() ? this.b64_to_utf8(this.getEnvData()['aws']): null;
	}

	public getS3Bucket = (): string => {
		return this.getEnvData() ? this.b64_to_utf8(this.getEnvData()['aws_bucket']): null;
	};
	public get s3Bucket() {
		return this.getEnvData() ? this.b64_to_utf8(this.getEnvData()['aws_bucket']): null;
	}

	public getS3Identity = (): string => {
		return this.getEnvData() ? this.b64_to_utf8(this.getEnvData()['aws_creds']): null;
	};
	public get s3Identity() {
		return this.getEnvData() ? this.b64_to_utf8(this.getEnvData()['aws_creds']): null;
	}

	public getS3ApiVersion = (): string => {
		return this.getEnvData() ? this.b64_to_utf8(this.getEnvData()['aws_api_version']): null;
	};
	public get s3ApiVersion() {
		return this.getEnvData() ? this.b64_to_utf8(this.getEnvData()['aws_api_version']): null;
	}

	public isProductionEnv = (): boolean => {
		return environment.production;
	};
	public get isProd() {
		return environment.production;
	}

	public getAccessToken = () => {
		return environment.access_token;
	};
	public get accessToken() {
		return environment.access_token;
	}

	public get encryptionKey() {
		return environment.encryption_key;
	}

	public getNumRecordsPerPage = () => {
		return this.numberOfRecords;
	};
	public get recordsPerPage() {
		return this.numberOfRecords;
	}

	getNoOfRecordsPerPage() {
		return this.noOfRecords;
	}

	setSideBarNode(activeNode) {
		this.sideBarNode = activeNode;
	}

	getSideBarNode() {
		return this.sideBarNode;
	}

	getScrollYThreshold() {
		return this.scrollYThreshold;
	}

	setDelayRecords() {
		this.timer = setTimeout(() => {
			this.showingRecords = true;
		}, 4000);
	}

	setClearTimeout() {
		window.clearTimeout(this.timer);
	}

	resetShowFlag() {
		this.showingRecords = false;
	}

	showResetFlag() {
		return this.showingRecords;
	}

	setScrollThrottle() {
		return this.infiniteScrollThrottle;
	}

	setScrollDistance() {
		return this.infiniteScrollDistance;
	}

	getAmountMaxLength() {
		return this.amountMaxLength;
	}

	public getMaxFileSize = () => {
		return this.maxFileSize;
	};

	public getIntercomAppId = () => {
		return this.getEnvData() ? this.b64_to_utf8(this.getEnvData()['intercom_app_id']): null;
	};
	public get intercomAppId() {
		return this.getEnvData() ? this.b64_to_utf8(this.getEnvData()['intercom_app_id']): null;
	}

	public getGoogleCaptchaKey = () => {
		return this.getEnvData() ? this.b64_to_utf8(this.getEnvData()['googleCaptcha']): null;
	};
	public get captchaKey() {
		return this.getEnvData() ? this.b64_to_utf8(this.getEnvData()['googleCaptcha']): null;
	}

	getDebounceTime() {
		return this.debounceTime;
	}

	getHubSpotTrackingCode() {
		return this.getEnvData() ? this.b64_to_utf8(this.getEnvData()['hubSpotTrackingCode']): null;
	}

	getNameSearchProductId(){
		return environment.nameSearchProductId;
	}

	b64_to_utf8(str) {	
		if(str) {
			return decodeURIComponent(window.atob(str));
		}
		else return null;
	}	
}
