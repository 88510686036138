
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../authentication/auth.service'
import { ConfigService } from '../config/config.service';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';

@Injectable()
export class MessagesService {
  direct: HttpClient;
  unnReadCount = 0;
  constructor(
    private router: Router,
    public auth: AuthService,
    private config: ConfigService,
    private http: HttpClient,
    private handler: HttpBackend
  ) { 
    this.direct = new HttpClient(this.handler);
  }

  getAllUserMessages(pageNum, sortBy, orderBy, searchStr) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
    }
    let url = this.config.getBasePath() + '/messages/user/' + this.auth.getUserId();
    url = url + '/page/' + pageNum + '/size/' + this.config.getNoOfRecordsPerPage();
    if (sortBy != '') {
      url = url + '/sort/' + sortBy + '/order/' + orderBy;
    }
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }

    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAllBackGroundUserMessages(pageNum, sortBy, orderBy, searchStr) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
    }
    let url = this.config.getBasePath() + '/messages/user/' + this.auth.getUserId();
    url = url + '/page/' + pageNum + '/size/' + this.config.getNoOfRecordsPerPage();
    if (sortBy != '') {
      url = url + '/sort/' + sortBy + '/order/' + orderBy;
    }
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }

    return this.direct.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAllUnreadCount() {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
    }
    let url = this.config.getBasePath() + '/messages/user/' + this.auth.getUserId() + '/unread/count'

    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        this.unnReadCount = response;
        return response;
      }),
      catchError((error: any) => observableThrowError(error)),);
  }

  getAllBackGroundUnreadCount() {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
    }
    let url = this.config.getBasePath() + '/messages/user/' + this.auth.getUserId() + '/unread/count'

    return this.direct.get(url, httpOptions).pipe(
      map((response: any) => {
        this.unnReadCount = response;
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  updateToRead(msgId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe:'response' as 'body'
    }
    let url = this.config.getBasePath() + '/messages/client/' + msgId + '/updatetoRead'
    return this.direct.put(url, null, httpOptions).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  updateParentId(msgData) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    };
  
    let url = this.config.getBasePath() + '/messages/' + msgData.Message_ID + '/updateparentid';
  
    return this.http.put(url, msgData, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        return observableThrowError(error.error);
      })
    );
  }

  getMsgData(msgId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
    }
    let url = this.config.getBasePath() + '/messages/message/' + msgId
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAllMsgs(msgId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
    }
    let url = this.config.getBasePath() + '/messages/parentmsg/' + msgId

    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAllMsgsByParentId(msgId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
    }
    let url = this.config.getBasePath() + '/messages/byparentmsgid/' + msgId

    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

    updateMsgToRead(msgData) {
      let httpOptions = {
        headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
        observe:'response' as 'body'
      }
        let url = this.config.getBasePath() + '/messages/client/update/read'
        return this.http.put(url, msgData, httpOptions).pipe(
            map((response: any) => {
                return response;
            }),
            catchError((error: any) => observableThrowError(error.error)),);
    }
    sendMessage(msg){
      let httpOptions = {
        headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
        observe:'response' as 'body'
      }
      let url = this.config.getBasePath() + '/messages/client/direct/'+msg['Sender_ID'];
      return this.http.post(url, msg, httpOptions).pipe(
        map((response: any) => {
          return response;
      }),
        catchError((error: any) => observableThrowError(error.error)),);
    }
}
