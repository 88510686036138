import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService, StripeService } from '@services';
import { Dict, get, isNil, to, __DEV__, getError } from '@utils';

@Component({
	selector: 'payment-method-add',
	template: `
		<!-- alert ‣ overall changes or errors -->
		<alert #alertRef [isOpen]="error | isNotNil" severity="error" variant="filled">
			{{ error }}
		</alert>

		<!-- title -->
		<div sx variant="dialog.header">
			<p sx variant="title">Add card</p>
		</div>

		<!-- forms -->
		<div sx variant="dialog.content">
			<stripe-input
				#cardRef
				(onChange)="alertRef.close()"
				(onValid)="handleValid()"
				(onInvalid)="handleInvalid()"
				[isLoading]="isLoading"
				autofocus="true"
				sx
				mb="10"
			></stripe-input>
			<br />
			<address
				#addressRef
				(onChange)="alertRef.close()"
				(onValid)="handleValid()"
				(onInvalid)="handleInvalid()"
				[isLoading]="isLoading"
				config="{ 'name': { 'placeholder': 'Cardholder' }}"
				[edit]="true"
				format="stripe"
			></address>
		</div>
		<!-- actions -->
		<div sx variant="dialog.actions">
			<button (click)="close()" class="btn btn-default">Cancel</button>
			
			<button
				(click)="handleSubmit(cardRef.element, addressRef.value)"
				[disabled]="isLoading || isUpdating || !isValid"
				class="btn btn-primary"
				sx
				variant="button.contained"
			>
				<span *ngIf="!isUpdating">Submit</span>
				<mat-spinner *ngIf="isUpdating" diameter="24"></mat-spinner>
			</button>
		</div>
	`,
})
export class PaymentMethodAdd implements OnInit {
	error: string;

	isLoading: boolean = true;

	isUpdating: boolean = false;

	isValid: boolean = false;

	/* ---------------------------------- */

	@ViewChild('addressRef', { static: true }) addressRef: any;

	@ViewChild('alertRef', { static: true }) alertRef: any;

	@ViewChild('cardRef', { static: true }) cardRef: any;

	/* ---------------------------------- */

	constructor(
		protected auth: AuthService,
		protected cdr: ChangeDetectorRef,
		protected stripe: StripeService,
		public dialog: MatDialogRef<PaymentMethodAdd>,
		@Inject(MAT_DIALOG_DATA) public data: { card: any },
	) {
		// If this is a dev environment, enhance the dev ergonomics by making global var available.
		if (__DEV__) {
			if (isNil(window['addPaymentMethod'])) window['addPaymentMethod'] = this;
		}
	}

	ngOnInit() {
		this.auth.getBillingAddress().subscribe(
		  address => {
			this.addressRef.patchValue(address);
			this.isLoading = false;
			this.cdr.detectChanges();
		  },
		  err => {
			console.log("add payment method error: ", err);
		  },
		);
	  }
	 

	// async ngOnInit() {
	// 	const [err, address] = await to(this.auth.getBillingAddress());
	// 	if (!err) this.addressRef.patchValue(address);
	// 	this.isLoading = false;
	// 	return this.cdr.detectChanges();
	// }

	_clearErrors = () => {
		this.error = null;
		return this.cdr.detectChanges();
	};

	/* ---------------------------------- */

	close = (card?: Dict): void => {
		this.dialog.close(card);
	};

	addCard = async (cardRef: any, address: Dict) => {
		const token = await to(this.stripe.createToken(cardRef, address), { throw: true });
		return await to(this.stripe.createCard(token.id, address), { throw: true });
	};

	handleValid = () => {
		if (this.cardRef.valid && this.addressRef.valid) {
			this.isValid = true;
		}
		return this.cdr.detectChanges();
	};

	handleInvalid = () => {
		if (!this.cardRef.valid || !this.addressRef.valid) {
			this.isValid = false;
		}
		return this.cdr.detectChanges();
	};

	handleSubmit = async (cardElement: any, address: Dict) => {
		this.isUpdating = true;
		this.addressRef.disable();
		this.cardRef.disable();
		const [err, card] = await to(this.addCard(cardElement, address));
		
		this.isUpdating = false;
		this.addressRef.enable();
		this.cardRef.enable();
		if (err) {

			this.error = getError(err);
			this.alertRef.open();
		} else {
			this.close(card);
		}
		return this.cdr.detectChanges();
	};
}
