import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { getProp } from '@styled';
import { get, isBool, isEq, isNil, __DEV__ } from '@utils';

/* ---------------------------------- */

declare var window: any;

/* ---------------------------------- */

@Component({
	selector: 'collapse',
	template: `
		<div
			#ref
			sx
			height="{{ _height }}"
			overflow="{{ _overflow }}"
			visibility="{{ _visibility }}"
			transition="height 250ms cubic-bezier(.4, 0, .2, 1)"
			willChange="height"
		>
			<ng-content></ng-content>
		</div>
	`,
})
export class Collapse implements OnChanges, OnInit, OnDestroy {
	//
	// ─────────────────────────────────────────────────────── INTERNAL STATE API ─────
	//

	/**
	 * If true the initial styles have been set.
	 */
	_isInit: boolean = false;

	/**
	 * The `height` style of the component.
	 */
	_height: any = 0;

	/**
	 * The `overflow` style of the component.
	 */
	_overflow: any = 'hidden';

	/**
	 * The `visibility` style of the component.
	 */
	_visibility: any = 'hidden';

	/**
	 * Root native element reference.
	 */
	get el() {
		return get(this, 'ref.nativeElement');
	}

	/** The height of the component content. */
	get scrollHeight() {
		const height = get(this, 'el.firstElementChild.scrollHeight') || 0;
		return height + 'px';
	}

	@ViewChild('ref', { static: true }) ref: any;

	//
	// ─────────────────────────────────────────────────────── EXTERNAL PROPS API ─────
	//

	/**
	 * If the component content is visible or not.
	 */
	@Input()
	get isOpen(): boolean {
		return this._isOpen;
	}
	set isOpen(val: boolean) {
		if (isBool(val)) {
			this._isOpen = val;
			this.cdr.detectChanges();
			if (this._isInit) this[val ? '_open' : '_close']();
		}
	}
	_isOpen: boolean = false;

	//
	// ────────────────────────────────────────────────────── EXTERNAL EVENTS API ─────
	//

	/**
	 * Handler triggered once the alert has started close animation.
	 */
	@Output() onClose: EventEmitter<any> = new EventEmitter();

	/**
	 * Handler triggered once the alert has finished close animation.
	 */
	@Output() onClosed: EventEmitter<any> = new EventEmitter();

	/**
	 * Handler triggered once the alert has started open animation.
	 */
	@Output() onOpen: EventEmitter<any> = new EventEmitter();

	/**
	 * Handler triggered once the alert has finished open animation.
	 */
	@Output() onOpened: EventEmitter<any> = new EventEmitter();

	//
	// ──────────────────────────────────────────────────────── LIFECYCLE METHODS ─────
	//

	constructor(protected cdr: ChangeDetectorRef) {
		// If this is a dev environment, enhance the dev ergonomics by making global var available.
		if (__DEV__) {
			if (isNil(window['collapse'])) window['collapse'] = this;
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		Object.keys(changes).forEach((prop: any) => {
			const { previousValue, currentValue, firstChange } = getProp(changes[prop]);
			const isOpenProp = prop === 'isOpen';
			const hasChanged = !isEq(previousValue, currentValue);
			const isInit = firstChange;
			const isOpen = currentValue;

			if (isOpenProp && hasChanged) {
				if (isOpen) {
					this._open(isInit);
				} else if (!isInit) {
					this._close();
				}
			}
		});
	}

	ngOnInit() {
		if (this.isOpen) this._opened();
		if (!this.isOpen) this._closed();
		this.el.addEventListener('transitionend', this._handleTransitionEnd);
		this._isInit = true;
	}

	ngOnDestroy() {
		this.el.removeEventListener('transitionend', this._handleTransitionEnd);
	}

	//
	// ───────────────────────────────────────────────────────── INTERNAL METHODS ─────
	//

	/**
	 * Handler triggered once the alert has started close animation.
	 * @ignore
	 */
	_close = () => {
		// Set height to 0 after setting measured height to trigger the transition.
		this._height = this.scrollHeight;
		requestAnimationFrame(() => {
			setTimeout(() => {
				this._height = 0;
				this._overflow = 'hidden';
			});
		});
		this.onClose.emit();
	};

	/**
	 * Handler triggered once the alert has finished close animation.
	 * @ignore
	 */
	_closed = () => {
		this._visibility = 'hidden';
		this.onClosed.emit();
	};

	/**
	 * Handler triggered once the alert has started open animation.
	 * @ignore
	 */
	_open = (isInit: boolean = false) => {
		this._visibility = 'visible';
		if (isInit) {
			this._height = 'auto';
			this._overflow = 'visible';
		} else {
			this._height = this.scrollHeight;
		}
		this.onOpen.emit();
	};

	/**
	 * Handler triggered once the alert has finished open animation.
	 * @ignore
	 */
	_opened = () => {
		console.log('_opened');
		this._height = 'auto';
		this._overflow = 'visible';
		this._visibility = 'visible';
		this.onOpened.emit();
	};

	/**
	 * Event handler that is called when the css animation ends.
	 * @ignore
	 */
	_handleTransitionEnd = (event: any) => {
		if (event.target === this.ref && event.propertyName === 'height') {
			if (this.isOpen) this._opened();
			if (!this.isOpen) this._closed();
		}
	};

	_addTransitionListener = () => {
		this.el.addEventListener('transitionend', this._handleTransitionEnd);
	};

	_removeTransitionListener = () => {
		this.el.removeEventListener('transitionend', this._handleTransitionEnd);
	};

	//
	// ───────────────────────────────────────────────────── EXTERNAL METHODS API ─────
	//

	/**
	 * Sets `isOpen` to true and shows the child content.
	 */
	open = () => {
		this.isOpen = true;
	};

	/**
	 * Sets `isOpen` to false and hides the child content.
	 */
	close = () => {
		this.isOpen = false;
	};

	/**
	 * Sets `isOpen` to the opposite value, toggling the child content visibility.
	 */
	toggle = () => {
		this.isOpen = !this.isOpen;
	};
}
