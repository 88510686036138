
import { forkJoin as observableForkJoin,  Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { AuthService, StaticTextService, NewOrderService, SettingsService, ConfigService } from '../../services';
import { Router } from '@angular/router';

import { DialogService } from "../../module/dialog/bootstrap-modal.module";
import { SuccessComponent } from '../../dialog/success/success.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {

  notifications: any = []
  error: any = '';
  timer: any;
  isQualiaClient: boolean = false;
  constructor(
    public auth: AuthService,
    private config: ConfigService,
    public text: StaticTextService,
    private newOrder: NewOrderService,
    private settingsServ: SettingsService,
    private router: Router,
    // private dialogService: DialogService
    private matDialog:MatDialog,
    private titleService: Title,
  ) { }

  ngOnInit() {
    this.titleService.setTitle(`Pippin Title™ - Client - User - Settings`)
    this.auth.getHubSpotTokenDetails();
		this.auth.loadHubSpotChatToken();
    this.auth.checkIfQualaiUser(this.auth.getUserId()).subscribe((data) => { this.isQualiaClient = data.status; }, (err) => { console.log("err", err); })
    this.loadFlags();
    this.timer = setTimeout(() => this.config.setSideBarNode(0), 0);
  }
  ngOnDestroy() {
    if (this.timer) clearTimeout(this.timer)
  }
  getCheckBoxValue(event: MatSlideToggleChange, index) {
    if (this.isQualiaClient) {
      this.openErrorDialog('Settings', 'You will receive all messages through Qualia. Please contact us if you have any questions.');
      event.source.checked = false;
    }
    else {
      if (event.checked) this.notifications[index].value = 1;
      else this.notifications[index].value = 0;
    }
  }

  goToNewOrder() {
    this.newOrder.goToNewOrder()
  }

  getFlagStatus(flagId, index) {
    this.settingsServ.getNotificationFlagValue(flagId)
      .subscribe((data) => {
        if (data) {
          var notification = this.notifications[index];
          if (data.Flag_Value == 1) notification.value = true
          else notification.value = false;
        } else {
          var notification = this.notifications[index];
          notification.value = false;
        }
      }, (err) => {

      })
  }

  saveAll() {

    var arr = [];
    var data =[];
    //var count=5;
    this.notifications.forEach((notification) => {
      data.push({
        User_ID: this.auth.getUserId(),
        Flag_Type_ID: notification.Flag_Type_ID,
        Flag_Value: Number(notification.value)
      })
      // arr.push(this.settingsServ.saveFlagValues(data));
      // count++;
    })
    this.settingsServ.saveFlagValues(data)
    // observableForkJoin(arr)
      .subscribe((data) => {

        //count--;
        // this.loadFlags(); 
        // if(count ==0){
        //   this.openDialog();     
        //   this.router.navigate(['/orders/current']);
        // }      
        this.openDialog();
        this.router.navigate(['/orders/current']);
      }, (err) => {

        this.error = this.text.get('ERR06')
      })
  }



  loadFlags() {
    this.settingsServ.getNotificationFlags()
      .subscribe((data) => {
        if (data.length) this.notifications = data;
        this.notifications.forEach((notification, index) => {
          this.getFlagStatus(notification.Flag_Type_ID, index)
        })
      }, (err) => {

      })
  }

  openDialog() {
    let dialogRef=this.matDialog.open(SuccessComponent,{
      data:{
        title: this.text.get('LBL08'),
        message: this.text.get('TXT28')
      }
    });

   
    // let disposable = this.dialogService.addDialog(SuccessComponent, {
    //   title: this.text.get('LBL08'),
    //   message: this.text.get('TXT28')
    // })
    //   .subscribe((res) => {

    //   });
  }

  openErrorDialog(title, msg) {
    let dialogRef=this.matDialog.open(ErrorDialogComponent,{
      data:{
        title: title,
        message: msg
      }
    });

    
    // let disposable = this.dialogService.addDialog(ErrorDialogComponent, {
    //   title: title,
    //   message: msg
    // })
    //   .subscribe((res) => { });
  }
  
}
