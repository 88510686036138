// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.errMsg {
  margin-top: -1.3em;
  font-size: 75%;
}

.dottedLine {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 1px;
  background-repeat: repeat-x;
  position: relative;
  background-position-y: 24px;
  padding-bottom: 8px;
  cursor: default;
}

.reqTime {
  margin-left: 18px !important;
  margin-bottom: 24px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/request-quote/request-quote1/request-quote1.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,cAAA;AACJ;;AAEC;EACG,4GAAA;EACA,wBAAA;EACA,2BAAA;EACA,kBAAA;EACA,2BAAA;EACA,mBAAA;EACA,eAAA;AACJ;;AAEE;EACC,4BAAA;EACC,8BAAA;AACJ","sourcesContent":[".errMsg {\n    margin-top: -1.3em;\n    font-size: 75%;\n }\n\n .dottedLine {\n    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);\n    background-size: 4px 1px;\n    background-repeat: repeat-x;\n    position: relative;\n    background-position-y: 24px;\n    padding-bottom: 8px;\n    cursor: default;    \n  } \n\n  .reqTime{\n   margin-left: 18px !important;\n    margin-bottom: 24px !important;\n}\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
