import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	Output,
	ViewChild,
} from '@angular/core';
import { StripeService, ThemeService } from '@services';
import { get, isNotEmptyString, isNil, __DEV__ } from '@utils';

@Component({
	selector: 'new-stripe-card',
	template: `
		<div
			sx
			pos="relative"
			h="32.8px !important"
			[_before]="{
				content: '',
				pos: 'absolute',
				left: '0',
				bottom: '0',
				w: '100%',
				h: '1px',
				bg: 'blackAlpha.600',
				transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
			}"
			[_after]="{
				content: '',
				pos: 'absolute',
				left: '0',
				bottom: '0',
				w: '100%',
				h: '2px',
				bg: 'secondary.main',
				transform: 'scaleX(0)',
				transition: 'transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
			}"
			[_hover]="{
				_before: {
					h: '2px',
					bg: 'blackAlpha.800'
				}
			}"
			_focus="{
        '_before': {
          'h': '1px'
        },
        '_after': {
          'transform': 'scaleX(1)'
        }
      }"
			_invalid="{
        '_after': {
          'bg': 'error.main',
          'transform': 'scaleX(1)'
        }
      }"
		>
			<div #cardRef sx py="2" px="3"></div>
		</div>
		<mat-error *ngIf="hasError" sx textAlign="left">
			<small>{{ error ? error : _error }}</small>
		</mat-error>
	`,
})
export class NewStripeCard implements AfterViewInit, OnDestroy {
	@ViewChild('cardRef', { static: true }) cardRef: ElementRef;

	/* ---------------------------------- */

	protected ref: any;

	public card: any;

	public brand: string;

	public isComplete: boolean = false;

	public isEmpty: boolean = true;

	public isValid: boolean = false;

	public _error: string;

	public tokens: any = [];

	public isFocused: boolean = false;

	/* ---------------------------------- */

	@Input() public error: string;

	/* ---------------------------------- */

	@Output() onChange: EventEmitter<any> = new EventEmitter();

	@Output() onIsComplete: EventEmitter<any> = new EventEmitter();

	@Output() onIsEmpty: EventEmitter<any> = new EventEmitter();

	@Output() onIsDirty: EventEmitter<any> = new EventEmitter();

	@Output() onIsValid: EventEmitter<any> = new EventEmitter();

	@Output() onIsInvalid: EventEmitter<any> = new EventEmitter();

	@Output() onError: EventEmitter<any> = new EventEmitter();

	@Output() onToken: EventEmitter<any> = new EventEmitter();

	/* ---------------------------------- */

	constructor(
		protected _stripe: StripeService,
		protected cdr: ChangeDetectorRef,
		public el: ElementRef,
		public tx: ThemeService,
	) {}

	ngAfterViewInit() {
		this.card = elements.create('card', {
			hidePostalCode: true,
			style: this._stripe.baseStyle,
		});
		this.card.mount(this.cardRef.nativeElement);
		this.card.addEventListener('change', this.handleChange);
		this.card.addEventListener('focus', this.handleFocus);
		this.card.addEventListener('blur', this.handleBlur);
		this.ref = document.querySelector('.StripeCard');
	}

	ngOnDestroy() {
		this.card.removeEventListener('change', this.handleChange);
		this.card.removeEventListener('focus', this.handleFocus);
		this.card.removeEventListener('blur', this.handleBlur);
		this.card.destroy();
		this.ref = null;
		this.tokens = [];
	}

	public get hasError() {
		return !isNil(this.error) || !isNil(this._error);
	}

	public clearErrors = () => {
		this.error = null;
		this._error = null;
		this.cdr.detectChanges();
	};

	protected handleChange = (event: any) => {
		console.log('stripe change: ', event);
		this.clearErrors();

		this._error = get(event, 'error.message');
		this.isComplete = get(event, 'complete', false);
		this.isEmpty = get(event, 'empty', true);
		this.isValid = this.isComplete && !this.hasError;
		this.cdr.detectChanges();

		if (this.isComplete && !this._error) {
			this.onIsValid.emit();
		} else if (!this.isComplete || this._error) {
			this.onIsInvalid.emit();
		}

		if (this._error) {
			this.cardRef.nativeElement.parentElement.setAttribute('data-invalid', true);
			this.onError.emit(this._error);
		} else {
			this.cardRef.nativeElement.parentElement.removeAttribute('data-invalid');
		}

		if (this.isEmpty) {
			this.onIsEmpty.emit();
		} else {
			this.onIsDirty.emit();
		}

		if (this.isComplete) {
			this.onIsComplete.emit();
		}

		this.onChange.emit(event);
		this.cdr.detectChanges();
	};

	protected handleFocus = () => {
		this.cardRef.nativeElement.parentElement.setAttribute('data-focus', true);
		this.isFocused = true;
		this.cdr.detectChanges();
	};

	protected handleBlur = () => {
		this.cardRef.nativeElement.parentElement.removeAttribute('data-focus');
		this.isFocused = false;
		this.cdr.detectChanges();
	};

	public clear = () => this.card.clear();

	public destroy = () => this.card.destroy();

	public focus = () => this.card.focus();

	public blur = () => this.card.blur();
}
