
import {forkJoin as observableForkJoin,  Observable, Subject } from 'rxjs';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AuthService, StaticTextService, PagerService, ConfigService, PricingService, NewOrderService,
  StateService, OrderListingService
} from '../services';
import { CONSTANTS } from '../app.constants'
import { Router } from '@angular/router';
import { PreloaderService } from '../services/preloader/preloader.service'
import { DialogService } from "../module/dialog/bootstrap-modal.module";
import { ErrorDialogComponent } from '../dialog/error-dialog/error-dialog.component';
import { threadId } from 'worker_threads';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
})
export class PricingComponent implements OnInit {
  displayedColumns:string[]=['Product_Description','County_Name','State_Short_Name','Price'];
  dataSource= new MatTableDataSource<any>([]);
  sortEvent:any=null;

  constants = CONSTANTS;
  pricings: any = []
  pricingStatus: any = {};
  pricingsToDisplay: any;
  totalCount: number;
  searchInProgress = false;
  states = [];
  counties = [];
  products = []
  filterBy = [];
  pager: any = {};
  selectedState = '';
  selectedCounty = '';
  selectedProduct = 0;
  searchString: string;
  sortField: string;
  orderBy: string;
  pageNum: number;
  scrollEnable: boolean = true;
  srollDistance=this.config.setScrollDistance();
  srollThrottle=this.config.setScrollThrottle();
  timer:any=[];
  searchQuery = new Subject<string>();
  hasScrollDown:boolean=false;

  constructor(
    private router: Router,
    public text: StaticTextService,
    private pagerService: PagerService,
    private config: ConfigService,
    private auth: AuthService,
    private pricingService: PricingService,
    private preloaderService: PreloaderService,
    private newOrder: NewOrderService,
    private orderListingService: OrderListingService,
    private stateService: StateService,
    private matDialog:MatDialog,
    private titleService: Title,
  ) {

    this.pager.pages = [];
    if(this.pageNum == undefined)
    this.pageNum = 1;
    this.orderBy = 'ASC';
    this.sortField = 'County_Name';
    this.searchString = '';
    this.searchQuery.pipe(
      debounceTime(this.config.getDebounceTime()),
      distinctUntilChanged())
      .subscribe(value => this.searchField(value));
  }

 

  ngOnInit() {
    this.titleService.setTitle(`Pippin Title™ - Client - Pricing`)
    this.auth.getHubSpotTokenDetails();
		this.auth.loadHubSpotChatToken();
    this.pricingService.getAvailableStateList().subscribe((states) => { this.states = states });
    this.getOrderProductsForClient();
    this.pricingStatus = this.pricingService.getPricingStatus();
    if (this.stateService.getpricings()) {
      this.initializeComponentData(this.stateService.getpricings());
    }
    else this.getInitialPricings();
    this.timer.push(setTimeout(() => this.config.setSideBarNode(5), 0));
  
  }

  getCostOrCustomAmount(pricedata) {
    if (this.checkCustomPriceAvlOrNot(pricedata)) {
      return pricedata.Custom_Pricing_Cost_Amount;
    } else {
      return pricedata.Cost_Amount;
    }
  }

  checkCustomPriceAvlOrNot(pricedata) {
    if(pricedata.Custom_Pricing_Cost_Amount == null) return false;
    else if((pricedata.Custom_Pricing_Cost_Amount == 0 || pricedata.Custom_Pricing_Cost_Amount) && pricedata.Custom_Cost_Status == CONSTANTS.customCostStatus.active)
    return true;
  }

  getOrderProductsForClient() {
    let userId;
    if (this.auth.getUserOrgId()) userId = this.auth.getUserOrgId();
    else userId = this.auth.getUserId();
    this.orderListingService.getOrderProductsForClient(userId, false)
      .subscribe((products) => {
        this.products = products; 
      })
  }

  goToNewOrder() {
    this.newOrder.goToNewOrder()
  }

  // setPage(page: number) {
  //   
  //   if (page < 1 || page > this.pager.totalPages) {
  //       return;
  //   }
  //   this.pageNum = page;
  //   this.setDisplayPricings(); 
  //   this.getPricings();   
  //   this.pager = this.pagerService.getPager(this.totalCount, this.pageNum); 
  //   
  // }

  // setSort(field: string) {
  //   this.pageNum = 1;
  //   if (field == this.sortField) {
  //     if (this.orderBy == 'ASC') this.orderBy = 'DESC';
  //     else this.orderBy = 'ASC';
  //   } else {
  //     this.sortField = field;
  //     this.orderBy = 'ASC';
  //   }
  //   this.getInitialPricings();
  // }

  setDisplayPricings() {
    let start = ((this.pageNum - 1) * this.config.getNumRecordsPerPage());
    this.pricingsToDisplay = this.pricings.slice(start, this.totalCount);
    if (this.pricingsToDisplay.length > this.config.getNumRecordsPerPage()) this.pricingsToDisplay = this.pricingsToDisplay.slice(0, this.config.getNumRecordsPerPage());
  }

  getCountyList(event) {
    if (event == 'all') {
      this.selectedCounty = 'all';
      // this.applyFilter();
    }
    if (event.length != 0 && event != 'all') {
      this.selectedCounty = '';
    
      this.pricingService.getAvailableCountyList(this.selectedState).subscribe((counties) => { this.counties = counties });
      // this.applyFilter();
    }
  }

  



  applyFilter() {
    this.pageNum = 1;
    this.filterBy = [];
    if (this.selectedState && this.selectedState != 'all') this.filterBy.push({ State_Short_Name: this.selectedState })
    if (this.selectedCounty && this.selectedCounty != 'all') this.filterBy.push({ County_Name: this.selectedCounty })
    if (this.selectedProduct && this.selectedProduct != -1) this.filterBy.push({ Product_ID: this.selectedProduct })
    this.getInitialPricings();
  }

  getPricings() {
    this.pageNum=1;
    this.stateService.setpricings(this);
    this.pricingService.getCustomPricings(this.pageNum, this.sortField, this.orderBy, this.getSearchString(), this.filterBy)
      .subscribe((result) => {
        if (result) {
          this.pricings = result.rows;
          this.totalCount = result.count;
          this.setDisplayPricings();
          this.pager = this.pagerService.getPager(this.totalCount, this.pageNum);
          this.searchInProgress = false;
        }
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
        // this.auth.logout();
        // this.router.navigate(['/login']);
      })

    
      
  }

  getInitialPricings() {
    if(this.pageNum == 1){
      this.stateService.setpricings(this);
      this.pricingService.getCustomPricings(this.pageNum, this.sortField, this.orderBy, this.getSearchString(), this.filterBy)
          .subscribe((result1) => {
          if (result1.count > this.config.getNumRecordsPerPage()) {
            this.pageNum++;
            this.pricingService.getCustomPricings(this.pageNum, this.sortField, this.orderBy, this.getSearchString(), this.filterBy)
              .subscribe((result2) => {
                var result = {
                  rows: result1.rows.concat(result2.rows),
                  count: result1.count
                }
                this.pricings = result.rows;
                this.totalCount = result1.count;
                this.getPricingsData(this.totalCount);
                this.dataSource.data=this.pricings;

              })
          }
          else {
            this.pricings = result1.rows;
            this.totalCount = result1.count;
            this.getPricingsData(this.totalCount);
            this.dataSource.data=this.pricings;
           
          }
  
        }, (err) => {
          
          this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
          // this.auth.logout();
          // this.router.navigate(['/login']);
        })
    }else{
      this.getRetainData();
    }
  }

  getRetainData(){
    var ObsCollection = [];
    for(var i=1; i<=this.pageNum; i++){
      ObsCollection.push(this.pricingService.getCustomPricings(i, this.sortField, this.orderBy, this.getSearchString(), this.filterBy))
    }
    observableForkJoin(ObsCollection)
    .subscribe((retainedPricings) => {
      if(retainedPricings){
        var totalOrders = [];
        for( var i=0; i<retainedPricings.length; i++){
          this.stateService.setpricings(this);
          totalOrders = totalOrders.concat(retainedPricings[i]['rows']);
        }
        this.pricings = totalOrders;
        this.totalCount = retainedPricings[0]['count'];
        this.getPricingsData(this.totalCount);
        this.dataSource.data=this.pricings;
      }
    })
  }
  // search(event){
  //   this.searchString=event; 
  //   var userComp = this;
  //   function waitForIt(curStr){
  //     if (userComp.searchInProgress) {
  //         this.timer.push(setTimeout(function(){waitForIt(curStr)},100));
  //     } else {
  //         if(userComp.searchString == curStr) userComp.findPricings();
  //     };
  //   }     
  //   waitForIt(this.searchString);
  // }
  searchField(search){
    var format = /[!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?]/;
    search = search.trim();
    this.searchString = search;
    if(!format.test(this.searchString)&& this.searchString==" "){
      this.searchString='';
      this.getInitialPricings();
    }else this.findPricings();
  }

  findPricings() {
    this.pageNum = 1;
    this.preloaderService.setSearchSpin();
    this.searchInProgress = true;
    this.getInitialPricings();
  }

  initializeComponentData(data) {
    this.pager = data.pager;
    this.pageNum = data.pageNum;
    this.searchString = data.searchString;
    this.orderBy = data.orderBy;
    this.sortField = data.sortField;
    this.selectedState = data.selectedState;
    this.selectedCounty = data.selectedCounty;
    this.selectedProduct = data.selectedProduct;
    this.filterBy = data.filterBy;
    this.counties = data.counties;
    this.products = data.products;
    this.totalCount = data.totalCount;
    this.pricingsToDisplay = data.pricingsToDisplay;
    this.searchInProgress = data.searchInProgress;
    this.getInitialPricings();
  }

  openErrorPopup(msg) {
    let dialogRef=this.matDialog.open(ErrorDialogComponent,{
      data:{
        message: msg
      }
    });

    // let disposable = this.dialogService.addDialog(ErrorDialogComponent, {
    //   message: msg
    // }).subscribe((res) => { });
  }

  getSearchString() {
    var format = /[!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?]/;
    if (format.test(this.searchString)) {
      return '';
    } else return this.searchString;
  }

  handleEvent(event){
    this.scrollEnable=event;
  }

  onScrollDown() {
    this.config.setClearTimeout();
    this.config.setDelayRecords();
    this.onScrollData();
  }

  onTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

  }

  onScrollData() {
    if (this.pageNum < this.pager.totalPages) {
      this.pageNum++;
      this.pricingService.getCustomPricings(this.pageNum, this.sortField, this.orderBy, this.getSearchString(), this.filterBy)
        .subscribe((result) => {
          if (result) {
            this.config.resetShowFlag();
            this.totalCount = result.count;
            this.pricings = this.pricings.concat(result.rows);
            this.pager = this.pagerService.getPager(this.totalCount, this.pageNum);
            this.dataSource.data=this.pricings;
            if(this.sortEvent){
              this.onSortChange(this.sortEvent)
              }
            this.hasScrollDown=false;
          }
        })

      
    }
  }

  getPricingsData(result){
      this.config.resetShowFlag();
      this.config.setClearTimeout();
      this.setDisplayPricings();
      this.pager = this.pagerService.getPager(result, this.pageNum, this.config.getNumRecordsPerPage());
      this.searchInProgress = false;
      this.config.setDelayRecords();
  } 

  ngOnDestroy(){
    if(this.timer){
      this.timer.forEach(time => clearTimeout(time));
      this.pricings=[];
    }
  }

  onTableScroll(e) {
        // Check if the scroll position is at the 90% of height of the table
         if (!this.hasScrollDown && ((e.target.scrollTop + e.target.clientHeight)/(e.target.scrollHeight))>0.9){
            this.hasScrollDown=true;
            this.onScrollDown()
        }
  }

  onSortChange(e){
    this.sortEvent=e;
    this.dataSource.data = this.applySortingLogic([...this.dataSource.data], e);
  }
  applySortingLogic(data: any[], sortEvent: any): any[] {
    if (sortEvent.direction === '') {
      return this.pricings;
    }
    const sortedData = data.sort((a, b) => {
      const isAsc = sortEvent.direction === 'asc';
      const column = sortEvent.active;
      if(column==='Price'){
        const valueA = this.getCostOrCustomAmount(a);  // Adjust this line based on your actual function
        const valueB = this.getCostOrCustomAmount(b);  
        const comparisonResult = (valueA > valueB) ? 1 : ((valueA < valueB) ? -1 : 0);
      return sortEvent.direction === 'asc' ? comparisonResult : -comparisonResult;
      }

      return isAsc ? a[column].localeCompare(b[column]) : b[column].localeCompare(a[column]);
    });

    return sortedData;
  }
 
}
