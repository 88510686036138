import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
	NewOrderService,
	ProgressBarService,
	StaticTextService,
	AuthService,
	ConfigService,
	AwsS3Service,
	FileService,
} from 'app/services';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UUID } from 'angular2-uuid';
import { CONSTANTS } from '../../app.constants';
import { DialogService } from '../../module/dialog/bootstrap-modal.module';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { ConfirmComponent } from '../../dialog/confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
	selector: 'app-request-quote2',
	templateUrl: './request-quote2.component.html',
	styleUrls: ['./request-quote2.component.scss'],
})
export class RequestQuote2Component implements OnInit {
	@ViewChild('fileInput', { static: true }) fileInput: ElementRef;

	primaryColor = '#0F9D58';
	quoteDetails: any = {};
	quoteForm: UntypedFormGroup;
	newDocs: any = [];
	selectedState = '';
	selectedCounty = '';
	timer: any;
	constants = CONSTANTS;
	specialInstructionText =
		'Please describe what product you are requesting, any documents you would like attached, and any other information relevant to the quote below';
	constructor(
		private newOrder: NewOrderService,
		private progressServ: ProgressBarService,
		public text: StaticTextService,
		private frmBuilder: UntypedFormBuilder,
		private router: Router,
		private auth: AuthService,
		private config: ConfigService,
		private s3Service: AwsS3Service,
		// private dialogService: DialogService,
		private matDialog: MatDialog,
		private fileService: FileService,
	) {
		this.quoteForm = frmBuilder.group({
			Property_Order_Instructions: [
				null,
				Validators.compose([Validators.required, Validators.maxLength(256)]),
			],
			Property_Order_Notes: [
				null,
				Validators.compose([Validators.maxLength(256)]),
			],
		});
	}

	ngOnInit() {
		this.auth.getHubSpotTokenDetails();
		this.auth.loadHubSpotChatToken();
		this.progressServ.setPrimaryColor(this.primaryColor);
		this.progressServ.setHeight(2);
		this.progressServ.setStage(1);
		this.progressServ.setTotalStages(3);
		this.progressServ.setProgressState((1.5 / 3) * 100);
		this.progressServ.setBarType(1);
		if (this.newOrder.quoteData) {
			this.quoteDetails = Object.assign({}, this.newOrder.quoteData);
			this.timer = setTimeout(() => this.config.setSideBarNode(0), 0);
		} else this.router.navigate(['/quote/' + this.auth.getUserId() + '/step1']);
		this.fetchUploadProgress();
	}

	fetchUploadProgress() {
		this.s3Service.progress.subscribe(res => {
			var matchedIndex = this.newDocs.findIndex((doc: any) => doc.name == res.name);
			if (this.newDocs[matchedIndex]) {
				this.newDocs[matchedIndex].completed =
					Math.round((res.progress.loaded / res.progress.total) * 100 * 100) / 100;
				this.newDocs[matchedIndex].completed = Math.round(
					this.newDocs[matchedIndex].completed,
				);
			}
		});
	}

	goBackToSep1() {
		this.router.navigate(['/quote/' + this.auth.getUserId() + '/step1/1']);
	}

	openFileSelector() {
		this.fileInput.nativeElement.value = null;
		this.fileInput.nativeElement.click();
	}

	uploadDocument(event) {
		if (this.validateFilenames(event)) {
			var fileUploads: any = [];
			if (this.checksize(event.target.files)) {
				for (var i = 0; i < event.target.files.length; i++) {
					var uid = UUID.UUID();
					this.newDocs.push({
						docId: uid,
						name: event.target.files[i].name,
						completed: 0,
					});
					fileUploads.push(this.uploadToS3AndUpdatePath(event.target.files[i], uid));
				}
				Promise.all(fileUploads)
					.then(files => {
						this.removeCompletedDocs(files);
						var succesFiles = files.filter(file => file !== undefined);
						if (succesFiles.length > 0)
							this.quoteDetails.orderDocId = this.quoteDetails.orderDocId.concat(
								succesFiles,
							);
					})
					.catch(err => {
						this.openErrorPopup(CONSTANTS.apiErrors.multiupload);
					});
			} else {
				this.openErrorPopup(CONSTANTS.apiErrors.docuploadsize);
			}
		} else {
			this.openErrorPopup(CONSTANTS.apiErrors.validateFile);
		}
	}

	validateFilenames(event) {
		var flag = 0;
		for (var i = 0; i < event.target.files.length; i++) {
			var filename = event.target.files[i].name;
			if (filename.indexOf(',') > -1) flag++;
		}
		if (flag == 0) return true;
		else return false;
	}

	checksize(files) {
		var largeFiles = Array.prototype.filter.call(
			files,
			file => file.size > this.config.getMaxFileSize() * 1024 * 1024,
		);
		if (largeFiles.length > 0) return false;
		else return true;
	}

	removeCompletedDocs(files) {
		for (var i = 0, len = files.length; i < len; i++) {
			for (var j = 0, len2 = this.newDocs.length; j < len2; j++) {
				if (files[i] && files[i].docId === this.newDocs[j].docId) {
					this.newDocs.splice(j, 1);
					len2 = this.newDocs.length;
				}
			}
		}
	}

	uploadToS3AndUpdatePath(file, propertyId) {
		return this.s3Service
			.onManagedUpload(file, '0')
			.then(data => {
				return this.s3Service
					.updateDocumentPath(
						'0',
						propertyId,
						file,
						data.Key,
						this.extractFilename(data.Key),
					)
					.then(doc => {
						doc['docId'] = propertyId;
						doc['completed'] = 100;
						return doc;
					})
					.catch(err => {
						throw err;
					});
			})
			.catch(err => {
				this.openErrorPopup(CONSTANTS.apiErrors.multiupload);
			});
	}

	extractFilename(filePath) {
		var parts = filePath.split('/');
		var filename = parts[parts.length - 1];
		return filename;
	}

	openErrorPopup(msg) {
		let dialogRef = this.matDialog.open(ErrorDialogComponent, {
			data: {
				message: msg
			}
		});



	}

	deleteFile(documents) {
		let dialogRef = this.matDialog.open(ConfirmComponent, {
			data: {
				title: 'Delete Document',
				message: 'Are you sure want to delete ' + documents.Document_Original_Name + ' ?',
			}
		});

		dialogRef.afterClosed().subscribe((res) => {
			if (res) {
				this.fileService
					.delete(documents.Order_Document_ID, this.auth.getUserId())
					.subscribe(
						data => {
							this.quoteDetails.orderDocId.splice(
								this.quoteDetails.orderDocId.indexOf(documents),
								1,
							);
						},
						err => {
							console.log(err);
							this.openErrorPopup(CONSTANTS.apiErrors.docdelete);
						},
					);
			} else {
				console.log('Closed', res);
			}

		});
	}

	goToFinalStep() {
		this.newOrder.quoteData = this.quoteDetails;
		this.router.navigate(['/quote/' + this.auth.getUserId() + '/step3']);
	}
}
