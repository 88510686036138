import {Injectable} from '@angular/core';
import {AbstractControl, UntypedFormGroup} from '@angular/forms';

@Injectable()
export class ErrorHandler {
  /**
   * @author Klement Omeri
   * This class is an injectable that serves to handle client and server side errors on forms.
   * Register this class in your module's PROVIDERS and then
   * INJECT it into any component's CONSTRUCTOR you want it to use.
   */


  /**
   * Checks if field is invalid and touched or dirty.
   * @param field   An object which holds required information
   */
  hasError(field: AbstractControl): boolean {
    return field.invalid && (field.touched || field.dirty);
  }


  /**
   * This function is used to get form field errors and returns only one of them
   *
   * @param form        Form to which to lookup to get formField
   * @param field       formControlName as a string
   *
   * @return message    Error message if field matches any of the error types below
   */
  getError(form: UntypedFormGroup, field: string): string {
    const formField = form.get(field);
    const formattedFieldName = this.getReformattedFieldName(field);
    if (formField) {
      let message = '';
      const errors = formField.errors;
      if (this.hasError(formField)) {
        if (errors.maxlength) {
          message = `Allowed max length for ${formattedFieldName} is ${errors.maxlength.actualLength}/${errors.maxlength.requiredLength}`;
        } else if (errors.email) {
          message = 'Email is not valid';
        } else if (errors.required) {
          message = `${formattedFieldName} is required`;
        } else if (errors.min) {
          message = `Min value for ${formattedFieldName} is ${errors.min.min}, actual value is ${errors.min.actual}`;
        } else if (errors.max) {
          message = `Max value for ${formattedFieldName} is ${errors.max.max}, actual value is ${errors.max.actual}`;
        } else if (errors.pattern) {
          message = `${formattedFieldName} is not valid`;
        } else if (errors.serverError) {
          message = errors.serverError;
        } else {
          message = '';
        }
      }
      return message;
    } else {
      // Message for developer in case of a wrong input for 'formControlName'.
      return `Field '${field}' can not be found in form. Check your formControlName attributes!`;
    }
  }


  /**
   * Sets error of type 'serverError' to fields of the given form.
   *
   * @param error      ErrorData instance
   * @param form       Form that error will be set to
   */
  setError(error: any, form: UntypedFormGroup) {
    if (error && form && error.length === undefined) {
      Object.keys(error).forEach(field => {
        const formControl = form.get(field);
        if (formControl) {
          formControl.setErrors({serverError: error[field]['msg']});
        }
      });
    }
  }


  /**
   * Get's a formControlName as a string and returns a user friendly name.
   * input: zip -> output: Zip
   * input: Address_Line -> output: Address Line
   *
   * @param  field          FormControlName as a string
   * @return  fieldName    Formatted field as a string
   */
  getReformattedFieldName(field: string): string {
    const isSingleWord = field.search('_') < 0;
    if (isSingleWord) {
      return `${field[0].toUpperCase()}${field.substring(1)}`;
    } else {
      const words = field.split('_');
      const fieldNames: string[] = [];
      for (const word of words) {
        fieldNames.push(`${word.toLowerCase()}`);
      }
      return `${fieldNames.join(' ')}`;
    }
  }

}
