import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { StaticTextService, AboutService, ConfigService } from '../../services';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrivacyComponent implements OnInit {
  //innerText:String = ''
  timer:any;
  constructor(
    public text:StaticTextService,
    private config:ConfigService,
    private pages: AboutService,
    private titleService: Title,
  ) { }

  ngOnInit() {
    // this.pages.getWithToken(3).subscribe((res)=>{
    //   this.innerText = res.Page_Text;
    // });
    this.titleService.setTitle(`Pippin Title™ - Client - Privacy Policy`)
    this.timer = setTimeout(() => this.config.setSideBarNode(0), 0);
  }

  ngOnDestroy(){
    if(this.timer){
      clearTimeout(this.timer)
    }
  }

}
