import { Component, ChangeDetectorRef, Inject } from '@angular/core';
import { DialogService, DialogComponent } from '../../module/dialog/bootstrap-modal.module';
import { OrderListingService, StaticTextService } from 'app/services';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import * as momentTimeZone from 'moment-timezone';
import * as moment from 'moment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-convert-order',
  templateUrl: './convert-order.component.html',
  styleUrls: ['./convert-order.component.scss']
})

export class ConvertOrderComponent{
  quoteDetails : any;
  orderModel : any = {};
  productDetails: any;
  convertOrderForm: UntypedFormGroup
  isPaymentFlag: boolean;
  constructor(private orderList: OrderListingService, private fb: UntypedFormBuilder,
    public text: StaticTextService, private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ConvertOrderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.quoteDetails= this.data.quoteDetails,
      this.productDetails= this.data.productDetails,
      this.isPaymentFlag= this.data.isPaymentFlag

    this.convertOrderForm = fb.group({
      Property_Order_Number: [null, Validators.compose([Validators.required, Validators.maxLength(255)])],
      Order_Type: [null, Validators.required],
    })
  }

  ngOnInit() {
    if(this.quoteDetails.selectedProduct) this.orderModel.selectedProduct = this.quoteDetails.selectedProduct
    if(this.quoteDetails.File_ID)this.orderModel.Property_Order_Number = this.quoteDetails.File_ID;
    this.cdr.detectChanges();
  }

  Close() {
    // this.result = { state: false }
    // this.close()
    this.dialogRef.close({ result: false })
  }

  createOrderForQuote() {
    let productFindIndex = this.productDetails.findIndex((product) => product.Product_ID == this.orderModel.selectedProduct);
    this.quoteDetails['selectedProduct'] = this.orderModel.selectedProduct;
    this.quoteDetails['Product_Description'] = this.productDetails[productFindIndex].Product_Description;
    this.quoteDetails['Product_Name'] = this.productDetails[productFindIndex].Product_Name;
    this.quoteDetails['File_ID'] = this.orderModel.Property_Order_Number;
    // this.result = {
    //   state : true,
    //   orderDetails : this.quoteDetails
    // }
    // this.close();
    this.dialogRef.close({
      state : true,
      orderDetails : this.quoteDetails
    })
  }

  getTotalStages() {
    if(this.isPaymentFlag) return 3;
    else return 2;
  }
}
