export interface ProgressResponse {
	name: string;
	progress: {
		loaded: number;
		total: number;
	};
}

export interface ManageUploadResponse {
	Bucket: string;
	ETag: string;
	key?: string;
	Key: string;
	Location: string;
}

export type UpdateDocumentPathResponse = {
	Document_Created_By: string;
	Document_Creation_Date: string;
	Document_Modification_Date: string;
	Document_Modified_By: string;
	Document_Name: string;
	Document_Original_Name: string;
	Document_Path: string;
	Document_Status: number;
	Document_Type: number;
	Order_Document_ID: bigint;
	Order_ID: string;
	Property_ID: string;
};
