import { Component, OnInit, ChangeDetectorRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, FormsModule, UntypedFormBuilder, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService, StaticTextService, ConfigService, } from '../../services';
import { CONSTANTS } from '../../app.constants';
// import {Criteria} from '@angular-material-extensions/password-strength/module/enum/criteria.enum';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreatePasswordComponent implements OnInit {
  // @ViewChild('passwordComponent', { static: true }) passwordComponent: MatPasswordStrengthComponent;

  changeFrm: UntypedFormGroup;
  changeModel: any = {};
  error = '';
  errorMsg: any = {};
  verification_ID: string;
  constants = CONSTANTS;
  @ViewChild('myfocus', { static: true }) myfocus:any;
  timer:any;
  constructor(
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public text: StaticTextService,
    private config: ConfigService
  ) {
    this.changeFrm = fb.group({
      'password': [null, Validators.compose([
        Validators.required, Validators.minLength(8),
        Validators.pattern(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/),
        // Validators.pattern(/(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])+/),
        Validators.maxLength(255),  
      ])],
      'confirmpassword': [null, Validators.required],
    });
    this.changeFrm.valueChanges.subscribe(data => {
      this.error = '';
    });
  }

  ngOnInit() {
    this.auth.loadHubSpotChatToken();
    this.auth.logout();
    this.route.params.subscribe((params) => {
      this.verification_ID = params['verificationId'];
    });
    this.timer=setTimeout(() => this.config.setSideBarNode(0), 0);
    this.myfocus.nativeElement.focus(); 
  }
  ngOnDestroy(){
    if(this.timer)clearTimeout(this.timer)
  }
  setPassword() {
    this.errorMsg.msg = '';
    this.changeModel['Verification_ID'] = this.verification_ID;
    this.changeModel['User_Password'] = this.changeModel.password;
    if (this.changeFrm.valid) {
      this.auth.setpassword(this.changeModel)
        .subscribe((res) => {
          // this.router.navigate(['login/changePassword']);
          //  this.router.navigate(['/base']);
          const loginModel = {};
          loginModel['Email_Address'] = res.body.replace(/['"]+/g, '');
          loginModel['User_Password'] = this.changeModel.password;
          this.login(loginModel)
        }, (error) => {
          this.errorMsg = error;
        });
    } else {
      // this.errorType = 'general';
      this.errorMsg.msg = 'Please enter correct details in all required fields';
    }
    // }


  }

  login(loginModel) {
    this.auth.login(loginModel)
      .subscribe(
        (response) => {
          if (!response || !response.token) this.router.navigate(['termsandconditions/' + response.User_ID]);
          else this.router.navigate(['/orders/current']);
        },
        (error) => {
          this.errorMsg = error;
        }
      );
  }

}
