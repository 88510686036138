import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import { ReactiveFormsModule,FormsModule,UntypedFormGroup,FormControl,Validators,UntypedFormBuilder} from '@angular/forms';
import { AuthService,EmailValidator,StaticTextService,EqualValidator , NewOrderService, ConfigService } from '../../services';
import { DialogService } from "../../module/dialog/bootstrap-modal.module";
import { SuccessComponent } from '../../dialog/success/success.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {

  editEmailFrm:UntypedFormGroup;
  error: string = '';
  errorType: string = '';
  errorMsg: any = {};
  editEmailModel: any = {};
  userData: any = {};
  timer:any;
  constructor(
    private router:Router,
    private frmBuilder:UntypedFormBuilder,
    private config:ConfigService,
    public text:StaticTextService,
    private newOrder:NewOrderService,
    private auth: AuthService,
    // private dialogService:DialogService
    private matDialog:MatDialog

  ) { 
    this.editEmailFrm = frmBuilder.group({
      'Email_Address':[null, Validators.compose([Validators.required, Validators.email, Validators.maxLength(100)])],
      'Confirm_Email_Address': [null,Validators.compose([Validators.required, Validators.email])],
      'Old_Email_Address':[null]
    })
    this.editEmailFrm.valueChanges.subscribe((data)=>{
      this.error = '';
    });
  }

  ngOnInit() {
    this.auth.getHubSpotTokenDetails();
		this.auth.loadHubSpotChatToken();
   //this.editEmailModel['Old_Email_Address'] = this.auth.getEmail();
   this.loadData();
   this.editEmailFrm.controls['Old_Email_Address'].disable();
   this.timer=setTimeout(() => this.config.setSideBarNode(0), 0);
  }

  ngOnDestroy(){
    if(this.timer) clearTimeout(this.timer);
  }

  goToAccountInfo(){
    this.router.navigate(['user/'+this.auth.getUserId()+'/profile']);
  }

  //  get diagnostic() {
  //   return JSON.stringify(this.editEmailModel);
    
  // }
  
  updateEmail(){
    this.userData['User_ID'] = this.auth.getUserId();
    this.userData['Email_Address'] = this.editEmailModel.Email_Address;
    this.auth.updateEmail(this.userData)
    .subscribe((res)=>{
        this.openDialog();
        this.auth.logout();
        this.router.navigate(['/login']);
    },(error)=>{
      
      this.errorMsg = error;
      this.errorType = error.type;
    });
  }

  changeModelVal(modelVal){
    this.errorMsg = {};
  }
  emailVerification(){
    
  }

  // display() {
  //   //
  // }

  goToNewOrder(){
    this.newOrder.goToNewOrder()
  }

  openDialog(){   

    let dialogRef=this.matDialog.open(SuccessComponent,
      {
        data:{
          title:this.text.get('LBL32') + ' '+ this.text.get('LBL14'),
          message: this.text.get('TXT28') + ' '+ this.text.get('TXT29')
        }
      })

     
    // let disposable = this.dialogService.addDialog(SuccessComponent,{
    //   title:this.text.get('LBL32') + ' '+ this.text.get('LBL14'),
    //   message: this.text.get('TXT28') + ' '+ this.text.get('TXT29')
    // })
    // .subscribe((res)=>{
      
    // });
  }

  loadData(){
    this.auth.getProfile()
    .subscribe((data)=>{
      
      if(data)this.editEmailModel['Old_Email_Address']=data.Email_Address;
    },(err)=>{
      
    })
  }

}
