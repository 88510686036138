import { Component, Inject, OnInit } from '@angular/core';
import { DialogComponent, DialogService } from "../../module/dialog/bootstrap-modal.module";
import { AuthService, StaticTextService } from '../../services';
import { Router } from '@angular/router';
import { CONSTANTS } from '../../app.constants'
import * as momentTimeZone from 'moment-timezone';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ConfirmModel {
  title: string;
  message: string;
}

@Component({
  selector: 'app-displaymessage',
  templateUrl: './displaymessage.component.html',
  styleUrls: ['./displaymessage.component.scss']
})
export class DisplaymessageComponent{
  constants=CONSTANTS
  // title: string;
  // message: string;
  title: any;
  message: any;

  constructor(dialogService: DialogService, public text: StaticTextService, private router: Router, public auth: AuthService, public dialogRef: MatDialogRef<DisplaymessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.title=this.data.title,
      this.message=this.data.message
  }

  confirm() {
    // we set dialog result as true on click on confirm button, 
    // then we can get dialog result from caller code 
    // this.result = true;
    // this.close();
    this.dialogRef.close({result:true})
  }

  Close() {
    // this.result = false;
    // this.close()
    this.dialogRef.close({ result: false })

  }

  showOrderDetails(orderId) {

    this.dialogRef.close();
    this.router.navigate(['orders/' + orderId + '/view']);
  }

  reply(message) {
    // this.result = message;
    
    // this.close();
    this.dialogRef.close({ result: message })

  }

  checkDaylight(etaDate) {
    if (momentTimeZone.tz(etaDate, 'America/New_York').isDST()) return 'EDT';
    else return 'EST'
  }

}
