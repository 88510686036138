// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.glyphicon-remove {
  top: 20px;
  right: 15px;
  font-size: 18px;
}

a {
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/cancel-dialog/cancel-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,WAAA;EACA,eAAA;AACJ;;AAEA;EACI,0BAAA;AACJ","sourcesContent":[".glyphicon-remove{\n    top: 20px;\n    right: 15px;\n    font-size: 18px;\n}\n\na {\n    text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
